import { Domains, DOMAIN_KEY } from '../../constants';
import { DomainInfo } from '../types';

interface CompanyService {
  getDomains(): Promise<Domains[]>;
  getDomain(): Domains | null;
  getDomainInfo(): DomainInfo | null;
}

export class BackendCompanyService implements CompanyService {
  constructor(readonly availableDomainInfos: DomainInfo[]) {}

  public getDomains = async (): Promise<Domains[]> => {
    return this.availableDomainInfos.map((d) => d.domain);
  };

  public getDomain = (): Domains | null => null;

  public getDomainInfo = (): DomainInfo | null => null;
}

export class LocalStorageCompanyService implements CompanyService {
  constructor(readonly availableDomainInfos: DomainInfo[]) {}

  public getDomain = (): Domains | null => {
    const stored: string | null = localStorage.getItem(DOMAIN_KEY);
    return this.availableDomainInfos.find((d) => d.domain === stored)?.domain ?? null;
  };

  public getDomains = () => Promise.resolve(this.availableDomainInfos.map((d) => d.domain));

  public getDomainInfo = () => {
    return this.availableDomainInfos.find((d) => d.domain === this.getDomain()) ?? null;
  };
}
