import { ApiDashboardList, DashboardConfig, DashboardId } from '../../common/components/dashboards/types';
import { allDashboardConfigsJson } from '../../settings/dashboard-configs/dashboard-configs-json';
import { DomainPreferences } from '../types';
import { DashboardNotFoundError } from './dashboard-errors';
import { DashboardService } from './dashboard-service';

export class FullViewDashboardService implements DashboardService {
  constructor(
    private readonly dashboardList: ApiDashboardList,
    private readonly domainPreferences: DomainPreferences
  ) {}

  public listDashboards = async () => {
    return this.dashboardList.dashboards;
  };

  public listCustomDashboardConfigs = async () => {
    const dashboardConfigsJson = allDashboardConfigsJson;
    return JSON.parse(dashboardConfigsJson(this.domainPreferences)) as DashboardConfig[];
  };

  // TODO: This method is probably same for all dashboard-services so
  // maybe DashboardService should be an abstract class or something
  public getCustomDashboardConfig = async (dashboardId: DashboardId) => {
    const dashboardConfigs = await this.listCustomDashboardConfigs();
    const dashboardConfig = dashboardConfigs.find((dc) => dc.id === dashboardId) ?? null;
    if (dashboardConfig === null) {
      return new DashboardNotFoundError(dashboardId);
    } else {
      return dashboardConfig;
    }
  };
}
