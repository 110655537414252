import { CircularProgress, CircularProgressProps, styled } from '@mui/material';
import React from 'react';

const LoadingWrapper = styled('div')`
  display: flex;
  margin: 8px;
  justify-content: center;
  align-content: middle;
`;

interface LoadingProps {
  color?: 'primary' | 'secondary';
  sx?: CircularProgressProps['sx'];
  size?: string;
}

export const LoadingCircle = (props: LoadingProps) => {
  const { color, sx, size } = props;
  return (
    <LoadingWrapper>
      <CircularProgress color={color ?? 'primary'} sx={sx} size={size ?? '1rem'} disableShrink />
    </LoadingWrapper>
  );
};
