import { RegularMetricId } from '../../../graphql/generated/graphql-sdk';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { Languages } from '../../constants';
import { TimeSelectionType } from '../../types';

export const ValentinDashboardChartConfig: DashboardConfig = {
  id: 'valentin',
  title: {
    [Languages.EN]: 'Valentin Project',
    [Languages.JA]: 'バレンタインプロジェクト',
  },
  enabled: true,
  pages: [
    {
      pageId: '1',
      dashboardId: 'valentin',
      pageNumber: 1,
      enabled: true,
      title: {
        [Languages.EN]: 'G3 Page',
        [Languages.JA]: 'G3 ページ',
      },
      pageOptions: {
        dataView: {
          enabled: true,
        },
        timeSlider: { enabled: false },
      },
      gridCols: 6,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: {
            [Languages.EN]: 'Individual Count',
            [Languages.JA]: '個人数',
          },
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearYearly,
                input: { start: 2023, end: 2024 },
              },
            },
          ],
        },
        {
          chartId: 'joiners-this-month',
          title: {
            [Languages.EN]: 'Joiners & Leavers',
            [Languages.JA]: '入社者と退職者',
          },
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
            },
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
              filters: `EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = ('自己都合')`,
            },
          ],
        },
      ],
    },
  ],
};
