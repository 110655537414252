import { DashboardConfig, DashboardId } from '../../common/components/dashboards/types';
import { AuthorizationService } from '../authorization/service';
import { DashboardNotAllowedError, DashboardNotFoundError } from './dashboard-errors';
import { DashboardService } from './dashboard-service';
import { DomainViewDashboardService } from './domain-view-dashboard-service';

export class UserViewDashboardService implements DashboardService {
  constructor(
    private readonly domainViewDashboardService: DomainViewDashboardService,
    private readonly authorizationService: AuthorizationService
  ) {}

  // Returns allowed dashboards with allowed pages
  public listDashboards = async () => {
    const domainAllowedDashboards = await this.domainViewDashboardService.listDashboards();
    const allowedDashboards = domainAllowedDashboards.filter((db) => {
      return this.authorizationService.isDashboardAllowedInExecutorRole(db.id);
    });
    const allowedDashboardsWithAllowedPages = allowedDashboards.map((db) => ({
      ...db,
      pages: db.pages.filter(
        (p) => p.enabled && this.authorizationService.isDashboardPageAllowedInExecutorRole(p.pageId)
      ),
    }));
    return allowedDashboardsWithAllowedPages;
  };

  public listCustomDashboardConfigs = async () => {
    const dashboardConfigs = await this.domainViewDashboardService.listCustomDashboardConfigs();
    return dashboardConfigs.filter(this.authorizationService.isCustomDashboardAllowedInExecutorRole);
  };

  public getCustomDashboardConfig = async (
    dashboardId: DashboardId
  ): Promise<DashboardConfig | DashboardNotFoundError | DashboardNotAllowedError> => {
    const dashboardConfig = await this.domainViewDashboardService.getCustomDashboardConfig(dashboardId);
    if (
      dashboardConfig instanceof DashboardNotFoundError ||
      this.authorizationService.isCustomDashboardAllowedInExecutorRole(dashboardConfig)
    ) {
      return dashboardConfig;
    } else {
      return new DashboardNotAllowedError(dashboardId);
    }
  };
}
