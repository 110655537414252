import { TFunction } from 'i18next';
import { partition, sum } from 'lodash';
import isEqual from 'lodash.isequal';
import { DataService } from '../../../api/data/service';
import { CustomSqlQueryValueBackendType } from '../../../api/data/types';
import {
  EmployeeCohortFilterDataManager,
  EmployeeFilterDataManager,
  EvaluationFilterDataManager,
  HierarchicalFilterDataManager,
  HireYearFilterDataManager,
  NonHierarchicalFilterDataManager,
} from '../../../api/data/utils';
import { EmployeeService } from '../../../api/employee/service';
import { Employee } from '../../../api/employee/types';
import { showEmployee } from '../../../api/employee/utils';
import { LatestDomainPreferences, VersionId } from '../../../api/types';
import { getFilterKey, isHierarchical } from '../../../api/utils';
import { Domains, employeeIdDataField, evaluationCycleTypeField, startDateField } from '../../../constants';
import {
  ApiMasterDataQueryFilterItem,
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EvaluationDataFields,
  Operations,
  ValueTypes,
} from '../../../types';
import {
  filterToDataFieldWithDataType,
  getKeyFromDataFieldWithDataType,
  hashCode,
  hierarchicalFieldFullLabel,
  nonHierarchicalFieldLabel,
} from '../../../utils';
import { Months, TimeSliderState } from '../timeslider/types';
import { Filter, FilterTypes } from './filterbar/types';
import {
  ContextLocation,
  DataFieldBasedSection,
  FilterSectionType,
  HierarchyItem,
  ItemsState,
  SearchBasedSection,
} from './types';

export const findByValuesInHierarchy = (values: (string | null)[], h: HierarchyItem[]): HierarchyItem | undefined => {
  const innerFindByValuesInHierarchy = (subHierarchy: HierarchyItem[]): HierarchyItem[] | undefined => {
    return subHierarchy.flatMap((i) => {
      if (isEqual(i.values, values)) {
        return [i];
      } else if (i.subItems) {
        return innerFindByValuesInHierarchy(i.subItems)?.flat() ?? [];
      } else {
        return [];
      }
    });
  };
  const result = innerFindByValuesInHierarchy(h);
  return result && result.length > 0 ? result.first() : undefined;
};

export const searchItems = (text: string, hierarchy: HierarchyItem[] | null): HierarchyItem[] | null => {
  const filterSubItems = (parents: HierarchyItem[]): HierarchyItem[] => {
    return parents.flatMap((item) => {
      const subItemsResult = item.subItems && item.subItems.length > 0 ? filterSubItems(item.subItems) : [];
      if (item.name?.toLowerCase()?.includes(text.toLowerCase()) || subItemsResult.length > 0) {
        return [{ ...item, subItems: subItemsResult.length > 0 ? subItemsResult : undefined }];
      } else {
        return [];
      }
    });
  };
  if (text.length === 0) {
    return null;
  }
  if (hierarchy && hierarchy.length > 0) {
    const result = filterSubItems(hierarchy);
    return result && result.length > 0 ? result : null;
  } else {
    return null;
  }
};

export const isFilterHierarchical = <T extends FilterTypes>(filter: T): boolean =>
  isHierarchical(filterToDataFieldWithDataType(filter));

export const isChecked = <T extends FilterTypes>(item: HierarchyItem, selectedFilters: T[]): boolean => {
  return selectedFilters.some((f) => f.key.hash === item.key.hash);
};

export const selectedFiltersCountInHierarchy = <T extends FilterTypes>(
  hierarchy: HierarchyItem[],
  selectedFilters: T[]
): number => {
  return sum(
    hierarchy.map(
      (i) =>
        (selectedFilters.some((f) => f.key.hash === i.key.hash) ? 1 : 0) +
        (i.subItems ? selectedFiltersCountInHierarchy(i.subItems, selectedFilters) : 0)
    )
  );
};

export const itemToFilters = <T extends FilterTypes>(item: HierarchyItem, level: number, label: string): T[] => {
  const itemIsHierarchical = isHierarchical(item.dimension);
  const values: (string | null)[] | (string | null)[][] =
    itemIsHierarchical && level > 0
      ? [item.values ?? []]
      : itemIsHierarchical && level === 0
      ? [item.values]
      : item.values;
  const base = {
    key: item.key,
    label,
    sql: item.sql,
    sqlOperator: item.sqlOperator,
  };
  return item.filterValues
    ? item.filterValues?.map(
        (fv) =>
          ({
            ...base,
            dataType: fv.dataType,
            property: fv.property,
            operation: fv.operation,
            values: fv.values,
            dontCombine: fv.dontCombine,
          } as T)
      )
    : ([
        {
          ...base,
          dataType: item.dimension.dataType,
          property: item.dimension.dataField,
          operation: Operations.EQUAL,
          values,
          dontCombine: !!item.sql,
        },
      ] as T[]); // TODO: figure out if this can be fixed
};

export const employeeToItem = (employee: Employee): HierarchyItem => ({
  key: hashCode(employee.id),
  itemId: employee.id,
  name: showEmployee(employee),
  values: [employee.id],
  dimension: employeeIdDataField,
  labelFn: () => showEmployee(employee),
  isSelectable: true,
});

export const toHierarchical = (field: DataFieldWithDataType): DataFieldWithDataType => {
  const indexOfLevel = field.dataField.indexOf('_LEVEL_');
  return {
    dataType: field.dataType,
    dataField: (indexOfLevel !== -1 ? field.dataField.slice(0, indexOfLevel) : field.dataField) as DataFields,
  };
};

export const getDataFieldSection = (
  f: DataFieldWithDataType,
  alias: string | null,
  timeSliderState: TimeSliderState,
  dataService: DataService,
  employeeService: EmployeeService,
  latestDomainPreferences: LatestDomainPreferences,
  latestEmployeeVersionId: VersionId | null,
  domain: Domains,
  keyPrefix: string,
  useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
): DataFieldBasedSection | SearchBasedSection => {
  const firstMonthOfYear = latestDomainPreferences.settings.finYearStartMonth ?? Months.January;
  if (isEqual(f, startDateField)) {
    const filterDataManager = new HireYearFilterDataManager(
      dataService,
      timeSliderState,
      firstMonthOfYear,
      useOldFilters
    );
    return {
      type: FilterSectionType.DATAFIELDBASED,
      key: hashCode(`${keyPrefix}-hireYear`),
      name: 'Hire Year',
      dataField: f,
      searchFn: (t, h) => Promise.resolve(searchItems(t, h)),
      labelFn: (t, defaultValue) => alias ?? t(`common:filterTray.hireYear`, defaultValue),
      filterDataManagerForFilterTray: filterDataManager,
      filterDataManagerForFilterBar: filterDataManager,
      isExpandable: true,
    };
  } else if (isEqual(f, evaluationCycleTypeField)) {
    const filterDataManager = new EvaluationFilterDataManager(
      dataService,
      latestDomainPreferences.settings.evaluationSettings?.evaluationCycles ?? [],
      domain,
      useOldFilters
    );
    return {
      type: FilterSectionType.DATAFIELDBASED,
      key: hashCode(`${keyPrefix}-evaluation`),
      name: 'Evaluation',
      dataField: f,
      searchFn: (t, h) => Promise.resolve(searchItems(t, h)),
      labelFn: (t, defaultValue) => alias ?? t(`common:api.masterDataField.evaluationScore`, defaultValue),
      filterDataManagerForFilterTray: filterDataManager,
      filterDataManagerForFilterBar: filterDataManager,
      isExpandable: true,
    };
  } else if (isHierarchical(f)) {
    const filterDataManager = new HierarchicalFilterDataManager(
      f,
      dataService,
      timeSliderState,
      latestDomainPreferences.settings,
      firstMonthOfYear
    );
    return {
      type: FilterSectionType.DATAFIELDBASED,
      key: hashCode(`${keyPrefix}-${getKeyFromDataFieldWithDataType(f)}`),
      name: getKeyFromDataFieldWithDataType(f),
      dataField: f,
      searchFn: (t, h) => Promise.resolve(searchItems(t, h)),
      labelFn: (t, defaultValue) => alias ?? t(`common:api.masterDataField.${f.dataField.toCamel()}`, defaultValue),
      filterDataManagerForFilterTray: filterDataManager,
      filterDataManagerForFilterBar: filterDataManager,
      isExpandable: true,
    };
  } else if (isEqual(f, employeeIdDataField)) {
    return {
      type: FilterSectionType.SEARCHBASED,
      key: hashCode(`${keyPrefix}-Employee`),
      name: 'Employee',
      dataField: f,
      searchFn: async (searchText: string) => {
        const result = await employeeService.search(searchText, latestEmployeeVersionId);

        return result.map(employeeToItem).map((apiItem) => ({
          key: apiItem.key,
          itemId: apiItem.itemId,
          name: apiItem.name,
          values: apiItem.values,
          dimension: apiItem.dimension,
          labelFn: (_, defaultValue: string) => apiItem.name ?? defaultValue,
          isSelectable: apiItem.isSelectable,
        }));
      },
      labelFn: (t, defaultValue, context) =>
        context.location === ContextLocation.FILTERBAR
          ? alias ?? t('common:api.masterDataField.employeeId', defaultValue)
          : t('common:filterTray.employees', defaultValue),
      filterDataManagerForFilterTray: new EmployeeFilterDataManager(),
      filterDataManagerForFilterBar: new NonHierarchicalFilterDataManager(
        f,
        dataService,
        timeSliderState,
        latestDomainPreferences.settings,
        firstMonthOfYear
      ),
      isExpandable: false,
    };
  } else {
    const filterDataManager = new NonHierarchicalFilterDataManager(
      f,
      dataService,
      timeSliderState,
      latestDomainPreferences.settings,
      firstMonthOfYear
    );
    const labelKey = `common:api.masterDataField.${f.dataField.toCamel()}`;
    return {
      type: FilterSectionType.DATAFIELDBASED,
      key: hashCode(`${keyPrefix}-${getKeyFromDataFieldWithDataType(f)}`),
      name: getKeyFromDataFieldWithDataType(f),
      dataField: f,
      searchFn: (t, h) => Promise.resolve(searchItems(t, h)),
      labelFn: (t, defaultValue) => alias ?? t(labelKey, defaultValue),
      filterDataManagerForFilterTray: filterDataManager,
      filterDataManagerForFilterBar: filterDataManager,
      isExpandable: true,
    };
  }
};

export const getEmployeeCohortFilterSection = (
  f: DataFieldWithDataType,
  alias: string | null,
  cohortValues: CustomSqlQueryValueBackendType[],
  latestDomainPreferences: LatestDomainPreferences,
  keyPrefix: string
): DataFieldBasedSection => {
  const labelKey = `common:api.masterDataField.${f.dataField.toCamel()}`;
  const filterDataManager = new EmployeeCohortFilterDataManager(f, cohortValues, latestDomainPreferences.settings);
  return {
    // TODO: Can it also be datafield based?
    type: FilterSectionType.DATAFIELDBASED,
    key: hashCode(`${keyPrefix}-${getKeyFromDataFieldWithDataType(f)}`),
    name: getKeyFromDataFieldWithDataType(f),
    dataField: f,
    searchFn: (t, h) => Promise.resolve(searchItems(t, h)),
    labelFn: (t, defaultValue) => alias ?? t(labelKey, defaultValue),
    filterDataManagerForFilterTray: filterDataManager,
    filterDataManagerForFilterBar: filterDataManager,
    isExpandable: true,
  };
};

export const movementUpdater = <T extends FilterTypes>(currentItems: T[], newItems: T[]) => {
  const [currentEvaluationItems, currentOtherItems] = partition(
    currentItems,
    (f) =>
      f.dataType === DataTypes.EVALUATION &&
      (f.property === EvaluationDataFields.EVALUATION_CYCLE_TYPE ||
        f.property === EvaluationDataFields.EVALUATION_AS_OF ||
        f.property === EvaluationDataFields.EVALUATION_SCORE)
  );

  const [currentHireYearItems, currentRegularItems] = partition(
    currentOtherItems,
    (i) => i.dataType === startDateField.dataType && i.property === startDateField.dataField
  );

  const newEvaluationItems = newItems.filter(
    (i) => i.dataType === evaluationCycleTypeField.dataType && i.property === evaluationCycleTypeField.dataField
  );

  return [
    ...(newEvaluationItems.find((i) =>
      currentEvaluationItems.some(
        (ei) =>
          ei.property === i.property &&
          (ei.property === EvaluationDataFields.EVALUATION_CYCLE_TYPE ||
            ei.property === EvaluationDataFields.EVALUATION_AS_OF) &&
          ei.values[0] !== i.values[0]
      )
    )
      ? []
      : currentEvaluationItems),
    ...(newItems.find((i) => i.dataType === startDateField.dataType && i.property === startDateField.dataField)
      ? []
      : currentHireYearItems),
    ...currentRegularItems,
    ...newItems,
  ];
};

export const segmentationUpdater = <T extends FilterTypes>(currentItems: T[], newItems: T[]) => {
  const sameDataField = newItems.every(
    (e) => currentItems.first()?.dataType === e.dataType && currentItems.first()?.property === e.property
  );
  if (!sameDataField) return newItems;
  const finalCurrentItems = currentItems.flatMap((currentItem) => {
    const sameHierarchicalLevel = newItems.every((newItem) => {
      const isNewItemParentOfCurrentItem = isEqual(newItem.values, currentItem.values.front());
      const isNewItemDecendantOfCurrentItem = isEqual(currentItem.values, newItem.values.front());
      return !isNewItemParentOfCurrentItem && !isNewItemDecendantOfCurrentItem;
    });
    return sameHierarchicalLevel ? [currentItem] : [];
  });
  return [...finalCurrentItems, ...newItems];
};

export const apiMasterDataQueryFilterItemToFilter = (
  filter: ApiMasterDataQueryFilterItem,
  t: TFunction,
  defaultValue: string
): Filter => {
  const dimension: DataFieldWithDataType = {
    dataType: filter.dataType,
    dataField: filter.property as DataFields,
  };
  const label = isHierarchical(dimension)
    ? filter.values.map((v) => hierarchicalFieldFullLabel(t, v as (string | null)[], dimension, defaultValue)).join(',')
    : nonHierarchicalFieldLabel(t, filter.values?.join(',') as string | null, dimension, defaultValue);
  return {
    key: getFilterKey(dimension, isHierarchical(dimension) ? (filter.values[0] as ValueTypes[]) : filter.values),
    label,
    operation: filter.operation,
    dataType: filter.dataType,
    property: filter.property as DataFields,
    values: filter.values,
    dontCombine: filter.dontCombine,
  };
};

export const itemStateToString = <T extends FilterTypes>(itemState: ItemsState<T>) => {
  return itemState.items.map((i) => i.key).join('-');
};
