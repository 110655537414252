import { DashboardConfig, DashboardId } from '../../common/components/dashboards/types';
import { Domains } from '../../constants';
import {
  defaultDashboardConfigsJson,
  jtbDashboardConfigsJson,
  jtDashboardConfigsJson,
  swanDashboardConfigsJson,
} from '../../settings/dashboard-configs/dashboard-configs-json';
import { DomainInfo, DomainPreferences } from '../types';
import { DashboardNotFoundError } from './dashboard-errors';
import { DashboardService } from './dashboard-service';
import { FullViewDashboardService } from './full-view-dashboard-service';

export class DomainViewDashboardService implements DashboardService {
  constructor(
    private readonly fullViewDashboardService: FullViewDashboardService,
    private readonly domainInfo: DomainInfo,
    private readonly latestPreferences: DomainPreferences
  ) {}

  public listDashboards = async () => {
    const companyAllowedDashboards: string[] =
      (this.domainInfo.latestSettings?.settings.allowedDashboards).map((d: any) => d.id) ?? [];
    const allDashboards = await this.fullViewDashboardService.listDashboards();
    return allDashboards.filter((d) => companyAllowedDashboards.includes(d.id));
  };

  public listCustomDashboardConfigs = async () => {
    let dashboardConfigsJson: string;
    switch (this.domainInfo.domain) {
      case Domains.SWAN:
        dashboardConfigsJson = swanDashboardConfigsJson(this.latestPreferences);
        break;
      case Domains.JT:
        dashboardConfigsJson = jtDashboardConfigsJson(this.latestPreferences);
        break;
      case Domains.JTB:
        dashboardConfigsJson = jtbDashboardConfigsJson(this.latestPreferences);
        break;
      default:
        dashboardConfigsJson = defaultDashboardConfigsJson(this.latestPreferences);
        break;
    }
    return JSON.parse(dashboardConfigsJson) as DashboardConfig[];
  };

  public getCustomDashboardConfig = async (dashboardId: DashboardId) => {
    const dashboardConfigs = await this.listCustomDashboardConfigs();
    const dashboardConfig = dashboardConfigs.find((dc) => dc.id === dashboardId) ?? null;
    if (dashboardConfig === null) {
      return new DashboardNotFoundError(dashboardId);
    } else {
      return dashboardConfig;
    }
  };
}
