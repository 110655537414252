import isEqual from 'lodash.isequal';
import { useMemo, useReducer } from 'react';
import { match, P } from 'ts-pattern';
import { useAliases } from '../../../api/alias/hooks';
import { useDomainDependencies, useLatestPreferences, usePermittedFilters } from '../../../api/hooks';
import { Domains } from '../../../constants';
import {
  useAliasServiceContext,
  useAuthorizationServiceContext,
  useBackendServiceContext,
  useDataServiceContext,
  useEmployeeServiceContext,
  useGlobalDomainContext,
  useGlobalLocaleContext,
  useLatestVersionsContext,
} from '../../../context/contexts';
import { FilterTypes } from './filterbar/types';
import {
  FilterSection,
  GlobalItemsWithHistoryHandle,
  GlobalItemsWithPersistenceAndHistoryHandle,
  ItemReducer,
  ItemsAction,
  ItemsActionWithHistory,
  ItemsState,
  ItemsStateWithHistory,
  Keyable,
  MultipleAction,
  SingleAction,
} from './types';

import identity from 'lodash.identity';
import sortBy from 'lodash.sortby';
import { defaultFiltersOrder } from '../../../settings/default_filters_order';
import { DataFields, DataFieldWithDataType, DataTypes, EMPLOYEE_COHORT_TYPES } from '../../../types';
import { nonFrontendQueryableFields, nonUserVisibleFields } from '../../../utils';
import { removeDuplicates } from '../../utils';
import { TimeSliderState } from '../timeslider/types';
import {
  getDataFieldSection,
  getEmployeeCohortFilterSection,
  movementUpdater,
  segmentationUpdater,
  toHierarchical,
} from './utils';

export const useGlobalFiltersWithPersistenceAndHistoryWrapper = <T extends Keyable>(
  domain: Domains,
  defaultFilters: T[],
  persistenceKeyPostfix?: string
): GlobalItemsWithPersistenceAndHistoryHandle<T> => {
  const persistenceKeyV1 = `${domain}-filters-v1${persistenceKeyPostfix ? `-${persistenceKeyPostfix}` : ''}`;
  const reducer: ItemReducer<
    T,
    ItemsStateWithHistory<T>,
    ItemsActionWithHistory<T>
  > = globalItemsWithPersistentAndHistoryReducerWrapper<T>(
    persistenceKeyV1,
    globalItemWithHistoryReducerWrapper<T>((state: ItemsState<T>, action: ItemsAction<T>) =>
      globalItemsReducer<T>(state, action)
    )
  );
  return useGlobalItemsWithPersistenceAndHistory<T>(persistenceKeyV1, defaultFilters, reducer);
};

export const useGlobalFiltersWithHistoryWrapper = <T extends Keyable>(
  defaultFilters: T[],
  postAction?: (l: T[]) => void
): GlobalItemsWithPersistenceAndHistoryHandle<T> => {
  return useGlobalItemsWithHistory<T>(defaultFilters, postAction);
};

export const useGlobalSegmentationWithPersistenceAndHistoryWrapper = <T extends FilterTypes>(
  domain: Domains,
  defaultSegmentations: T[],
  level = 1,
  persistenceKeyPostfix?: string,
  postAction?: (l: T[]) => void
): GlobalItemsWithPersistenceAndHistoryHandle<T> => {
  const persistenceKeyV1 = `${domain}-segmentations-level-${level ?? '1'}-v1${
    persistenceKeyPostfix ? `-${persistenceKeyPostfix}` : ''
  }`;
  const reducer: ItemReducer<
    T,
    ItemsStateWithHistory<T>,
    ItemsActionWithHistory<T>
  > = globalItemsWithPersistentAndHistoryReducerWrapper<T>(
    persistenceKeyV1,
    globalItemsWithPostActionReducerWrapper(
      postAction ?? identity,
      globalItemWithHistoryReducerWrapper<T>(
        globalItemsWithOverwriteReducerWrapper(segmentationUpdater, (state: ItemsState<T>, action: ItemsAction<T>) =>
          globalItemsReducer<T>(state, action)
        )
      )
    )
  );
  return useGlobalItemsWithPersistenceAndHistory<T>(persistenceKeyV1, defaultSegmentations, reducer);
};

export const useGlobalMovementFilterWithPersistenceAndHistoryWrapper = <T extends FilterTypes>(
  domain: Domains,
  defaultFilters: T[]
): GlobalItemsWithPersistenceAndHistoryHandle<T> => {
  const persistenceKeyV1 = `${domain}-filters-movement-v1`;
  const reducer: ItemReducer<
    T,
    ItemsStateWithHistory<T>,
    ItemsActionWithHistory<T>
  > = globalItemsWithPersistentAndHistoryReducerWrapper<T>(
    persistenceKeyV1,
    globalItemWithHistoryReducerWrapper<T>(
      globalItemsWithOverwriteReducerWrapper(movementUpdater, (state: ItemsState<T>, action: ItemsAction<T>) =>
        globalItemsReducer<T>(state, action)
      )
    )
  );
  return useGlobalItemsWithPersistenceAndHistory<T>(persistenceKeyV1, defaultFilters, reducer);
};

export const useGlobalItemsWithPersistenceAndHistory = <T extends Keyable>(
  persistenceKey: string,
  defaultItems: T[],
  underlyingReducer: ItemReducer<T, ItemsStateWithHistory<T>, ItemsActionWithHistory<T>>
): GlobalItemsWithPersistenceAndHistoryHandle<T> => {
  const stored: string | null = localStorage.getItem(persistenceKey);
  const storedItems: T[] = stored ? (JSON.parse(stored) as unknown as T[]) : [];
  const items = removeDuplicates([...defaultItems, ...storedItems]);
  const initialState = {
    items,
    history: items.length > 0 ? [[], items] : [[]],
    historyIndex: items.length > 0 ? 1 : 0,
    undoActive: items.length > 0,
    redoActive: false,
    resetActive: items.length > 0,
  };

  return useReducer(
    (state: ItemsStateWithHistory<T>, action: ItemsActionWithHistory<T>) => underlyingReducer(state, action),
    initialState
  );
};

export const useGlobalItemsWithHistory = <T extends Keyable>(
  defaultItems: T[],
  postAction?: (l: T[]) => void
): GlobalItemsWithHistoryHandle<T> => {
  const items = defaultItems;
  const initialState = {
    items,
    history: items.length > 0 ? [[], items] : [[]],
    historyIndex: items.length > 0 ? 1 : 0,
    undoActive: items.length > 0,
    redoActive: false,
    resetActive: items.length > 0,
  };

  const reducer = (state: ItemsStateWithHistory<T>, action: ItemsActionWithHistory<T>) =>
    globalItemsWithPostActionReducerWrapper(
      postAction ?? identity,
      globalItemWithHistoryReducerWrapper<T>(globalItemsReducer<T>)
    )(state, action);
  return useReducer(reducer, initialState);
};

export const globalItemsWithPersistentAndHistoryReducerWrapper = <T extends Keyable>(
  persistenceKey: string,
  underlyingReducerWithHistory: ItemReducer<T, ItemsStateWithHistory<T>, ItemsActionWithHistory<T>>
): ItemReducer<T, ItemsStateWithHistory<T>, ItemsActionWithHistory<T>> => {
  return (state: ItemsStateWithHistory<T>, action: ItemsActionWithHistory<T>): ItemsStateWithHistory<T> => {
    const newstate = underlyingReducerWithHistory(state, action);
    localStorage.setItem(persistenceKey, JSON.stringify(newstate.items));
    return newstate;
  };
};

export const globalItemWithHistoryReducerWrapper = <T extends Keyable>(
  underlyingReducer: ItemReducer<T, ItemsState<T>, ItemsAction<T>>
): ItemReducer<T, ItemsStateWithHistory<T>, ItemsActionWithHistory<T>> => {
  return (state: ItemsStateWithHistory<T>, action: ItemsActionWithHistory<T>): ItemsStateWithHistory<T> => {
    return match(action)
      .with(
        { type: P.union('replace', 'add-single', 'remove-single', 'add-multiple', 'remove-multiple', 'reset') },
        (regularAction) => {
          const newState = underlyingReducer(state, regularAction);
          switch (regularAction.type) {
            case 'replace':
              return {
                ...newState,
                history: [...state.history.slice(0, state.historyIndex + 1), [...newState.items]],
                historyIndex: state.historyIndex + 1,
                undoActive: true,
                redoActive: false,
              };
            case 'reset':
              return {
                ...newState,
                history: [...state.history.slice(0, state.historyIndex + 1), []],
                historyIndex: state.historyIndex + 1,
                redoActive: false,
                undoActive: true,
              };
            case 'add-single': {
              return {
                ...newState,
                history: [...state.history.slice(0, state.historyIndex + 1), [...newState.items]],
                historyIndex: state.historyIndex + 1,
                undoActive: true,
                redoActive: false,
              };
            }
            case 'remove-single':
              return {
                ...newState,
                history: [...state.history.slice(0, state.historyIndex + 1), [...newState.items]],
                historyIndex: state.historyIndex + 1,
                undoActive: true,
                redoActive: false,
              };
            case 'add-multiple':
              return {
                ...newState,
                history: [...state.history.slice(0, state.historyIndex + 1), [...newState.items]],
                historyIndex: state.historyIndex + 1,
                undoActive: true,
                redoActive: false,
              };
            case 'remove-multiple':
              return {
                ...newState,
                history: [...state.history, [...newState.items]],
                historyIndex: state.historyIndex + 1,
                undoActive: true,
                redoActive: false,
              };
          }
        }
      )
      .with({ type: P.union('undo', 'redo') }, (historyAction) => {
        return match(historyAction.type)
          .with('undo', () => {
            const historyIndex = Math.max(0, state.historyIndex - 1);
            const items = state.history[historyIndex];
            return {
              ...state,
              items,
              historyIndex,
              undoActive: historyIndex > 0,
              redoActive: true,
              resetActive: items.length > 0,
            };
          })
          .with('redo', () => {
            const historyIndex = Math.min(state.history.length - 1, state.historyIndex + 1);
            const items = state.history[historyIndex];
            return {
              ...state,
              items,
              historyIndex,
              undoActive: true,
              redoActive: historyIndex < state.history.length - 1,
              resetActive: items.length > 0,
            };
          })
          .exhaustive();
      })
      .exhaustive();
  };
};

export const globalItemsReducer = <T extends Keyable>(state: ItemsState<T>, action: ItemsAction<T>): ItemsState<T> => {
  return match(action)
    .with({ type: 'replace' }, (a: MultipleAction<T>) => {
      return { ...state, items: a.items, resetActive: true };
    })
    .with({ type: 'reset' }, () => {
      return { ...state, items: [], resetActive: false };
    })
    .with({ type: 'add-single' }, (a: SingleAction<T>) => {
      return {
        ...state,
        items: removeDuplicates([...state.items, a.item]),
        resetActive: true,
      };
    })
    .with({ type: 'remove-single' }, (a: SingleAction<T>) => {
      const items = state.items.filter((f) => f.key.hash !== a.item.key.hash);
      return { ...state, items, resetActive: items.length > 0 };
    })
    .with({ type: 'add-multiple' }, (a: MultipleAction<T>) => {
      return {
        ...state,
        items: removeDuplicates([...state.items, ...a.items]),
        resetActive: true,
      };
    })
    .with({ type: 'remove-multiple' }, (a: MultipleAction<T>) => {
      const items = [...state.items.filter((f) => !a.items.some((a) => a.key.hash === f.key.hash))];
      return {
        ...state,
        items,
        resetActive: items.length > 0,
      };
    })
    .exhaustive();
};

const globalItemsWithPostActionReducerWrapper = <T extends Keyable>(
  postAction: (l: T[]) => void,
  underlyingReducer: ItemReducer<T, ItemsStateWithHistory<T>, ItemsActionWithHistory<T>>
) => {
  return (state: ItemsStateWithHistory<T>, action: ItemsActionWithHistory<T>): ItemsStateWithHistory<T> => {
    const newState = underlyingReducer(state, action);
    postAction(newState.items);
    return newState;
  };
};

export const globalItemsWithOverwriteReducerWrapper = <T extends Keyable>(
  updater: (l1: T[], l2: T[]) => T[],
  underlyingReducer: ItemReducer<T, ItemsState<T>, ItemsAction<T>>
) => {
  return (state: ItemsState<T>, action: ItemsAction<T>): ItemsState<T> => {
    return match(action)
      .with({ type: 'replace' }, () => {
        return underlyingReducer(state, action);
      })
      .with({ type: 'reset' }, () => {
        return underlyingReducer(state, action);
      })
      .with({ type: 'remove-single' }, () => {
        return underlyingReducer(state, action);
      })
      .with({ type: 'remove-multiple' }, () => {
        return underlyingReducer(state, action);
      })
      .with({ type: 'add-single' }, (a: SingleAction<T>) => {
        return {
          ...state,
          items: updater(state.items, [a.item]),
          resetActive: true,
        };
      })
      .with({ type: 'add-multiple' }, (a: MultipleAction<T>) => {
        return {
          ...state,
          items: updater(state.items, a.items),
          resetActive: true,
        };
      })
      .exhaustive();
  };
};

export const useNonNullFields = () => {
  const backendService = useBackendServiceContext();
  const { data: domainDependencies } = useDomainDependencies(backendService);
  return useMemo(() => {
    const usedFields: DataFieldWithDataType[] =
      domainDependencies?.domainInfo?.allUsedFields?.flatMap((d) =>
        d.datafields.map((df) => ({ dataType: d.dataType as unknown as DataTypes, dataField: df as DataFields }))
      ) ?? [];
    const excludedFields = [...nonUserVisibleFields, ...nonFrontendQueryableFields];
    const filteredUsedFields = usedFields.filter(
      (f) => !excludedFields.some((ef) => ef.dataType === f.dataType && ef.dataField === f.dataField)
    );
    return filteredUsedFields.map(toHierarchical);
  }, [domainDependencies?.domainInfo?.allUsedFields]);
};

export const useAllowedFields = () => {
  const nonNullFields = useNonNullFields();
  const backendService = useBackendServiceContext();
  const authorizationService = useAuthorizationServiceContext();
  const permittedFilters = usePermittedFilters(backendService, authorizationService);
  const allowedFields = nonNullFields.filter((f) => authorizationService.isFieldAllowedForExecutorRole(f));
  return permittedFilters?.filter((f) => allowedFields.some((nnf) => isEqual(nnf, f))) ?? null;
};

export const useListUsedCohorts = () => {
  const backendService = useBackendServiceContext();
  const { data: domainDependencies } = useDomainDependencies(backendService);
  const usedCohorts = domainDependencies?.domainInfo?.usedCohorts ?? [];
  return usedCohorts;
};

export const useAvailableFilterSections = (
  keyPrefix: string,
  fields: DataFieldWithDataType[],
  timeSliderState: TimeSliderState,
  useOldFilters?: boolean //TODO: this is temporary for movement db sql filters cannot be used
): FilterSection[] => {
  const backendService = useBackendServiceContext();
  const latestVersions = useLatestVersionsContext();
  const latestEmployeeVersionId = latestVersions[DataTypes.EMPLOYEE] ?? null;
  const employeeService = useEmployeeServiceContext();

  const aliasService = useAliasServiceContext();
  const { data: aliases } = useAliases(aliasService);
  const locale = useGlobalLocaleContext();

  const dataService = useDataServiceContext();
  const domainPreferences = useLatestPreferences(backendService);
  const domain = useGlobalDomainContext();

  const dataFieldBasedFilterSections: FilterSection[] = sortBy(
    fields?.map((f) => {
      const alias = aliasService.getAliasForDataField(aliases, locale.selected)(f);
      return getDataFieldSection(
        f,
        alias,
        timeSliderState,
        dataService,
        employeeService,
        domainPreferences,
        latestEmployeeVersionId,
        domain,
        keyPrefix,
        useOldFilters
      );
    }) ?? [],
    (f) => defaultFiltersOrder.findIndex((fo) => isEqual(fo, f.dataField))
  );

  const filterSections = [...dataFieldBasedFilterSections];
  return filterSections;
};

export const useEmployeeCohortFilterSections = (
  cohortType: EMPLOYEE_COHORT_TYPES,
  keyPrefix: string
): FilterSection[] => {
  const usedCohorts = useListUsedCohorts();
  const aliasService = useAliasServiceContext();
  const backendService = useBackendServiceContext();
  const domainPreferences = useLatestPreferences(backendService);
  const { data: aliases } = useAliases(aliasService);
  const locale = useGlobalLocaleContext();
  const cohortValues = usedCohorts.find((c) => c.cohortType === cohortType)?.cohortValues ?? [];
  const employeeCohortFilterSections = cohortValues.map((c) => {
    const dimension = {
      dataType: DataTypes.EMPLOYEE,
      dataField: c.cohortKey,
    } as DataFieldWithDataType;
    const alias = aliasService.getAliasForDataField(aliases, locale.selected)(dimension);
    return getEmployeeCohortFilterSection(dimension, alias, c.cohortValues, domainPreferences, keyPrefix);
  });
  return employeeCohortFilterSections;
};
