import DataLoader from 'dataloader';
import { match } from 'ts-pattern';
import { AuthService } from '../auth/auth-service';
import { DataFields, DataTypes, EmployeeDataFields, Operations } from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import {
  CohortMetricId,
  EmployeeCohortMetricId,
  GroupingInput,
  RegularMetricId,
  SqlQueryResponse,
  TimeSegment,
  TimeSelectionInput,
} from '../graphql/generated/graphql-sdk';
import { IPermissionsStore } from '../permissions/permissions-store';
import {
  ApiCustomSqlQueryResponseDataPoint,
  ApiCustomSqlQueryResponseDataPointDimensionData,
  ApiCustomSqlQueryResponseDataPointMeasureData,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataBatchQueryResponse,
  ApiMasterDataBatchQueryResponseItem,
  ApiMasterDataField,
  ApiMasterDataMovementQuery,
  ApiMasterDataQuery,
  ApiMasterDataQueryMeasure,
  ApiMasterDataQueryMeasureOperation,
  ApiMasterDataQueryResponse,
  ApiMasterDataTypes,
  ApiMetricQueryResponse,
  ApiQueryTypes,
} from './api-interfaces';
import { CachedMasterDataService } from './cached-master-data-service';
import { GraphQlRequestService } from './graphql-request-service';
import { CustomSqlQueryValueBackendType, MetricResultFailure, MetricResultSuccess } from './graphql-types';
import { parseCustomSqlValue, parseResponseValue } from './graphql-utils';
import { RequestService } from './request-service';
import { handleGQLErrors } from './utils';

export interface BenchmarkConfig {
  prduId?: string | null;
  pdu?: boolean;
  limitedToPermittedPopulation?: boolean;
}
declare global {
  interface Window {
    missingVersionIdQueries: Array<ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery>;
  }
}

window.missingVersionIdQueries = [];

const missingVersionIdWarningMessage =
  "The following queries don't contain any version id filter. The backend will automatically add the latest version id filter. If that's what you need, add it explicitly to make the intention clear.";

const checkQueryContainsVersionId = (
  query: ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery
) => {
  if (
    !query.filterItems?.some((f) => {
      return f.property === 'VERSION_ID';
    })
  ) {
    window.missingVersionIdQueries.push(query);
  }
};

if (!environmentService.isProd && window.missingVersionIdQueries.length > 0) {
  console.warn(missingVersionIdWarningMessage, window.missingVersionIdQueries);
}

type QueryInput<Q, R> = {
  query: Q;
  processResponse: (result: R) => ApiMasterDataQueryResponse;
};

type ApiCustomSqlQuery = { querySql: string; disableNestLoop?: boolean };

interface CustomSqlQueryInput extends QueryInput<ApiCustomSqlQuery, SqlQueryResponse> {
  queryType: ApiQueryTypes.SQL;
  query: ApiCustomSqlQuery;
  processResponse: (result: SqlQueryResponse) => ApiMasterDataQueryResponse;
}

interface MovementQueryInput extends QueryInput<ApiMasterDataMovementQuery, string | null> {
  queryType: ApiQueryTypes.MOVEMENT;
  query: ApiMasterDataMovementQuery;
  processResponse: (result: string | null) => ApiMasterDataQueryResponse;
}

type QueryInputType = CustomSqlQueryInput | MovementQueryInput;

export type SQLFilters = string;

export interface MasterDataApiService {
  executeCustomSqlQuery(
    domain: string,
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    query: string,
    disableNestLoop?: boolean
  ): Promise<ApiMasterDataQueryResponse>;
  executeMovementQuery(domain: string, query: ApiMasterDataMovementQuery): Promise<ApiMasterDataQueryResponse>;
  queryCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    userFilters?: SQLFilters,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse>;
  queryEmployeeCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: EmployeeCohortMetricId[],
    userFilters?: SQLFilters,
    userCohortFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse>;
  queryMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    userFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse>;
}

// This service uses the new backend for old, advanced and movement queries and otherwise uses the old backend
// tslint:disable-next-line: max-classes-per-file
export class NewBackendMasterDataApiService implements MasterDataApiService {
  graphQlRequestService: GraphQlRequestService;
  permissionStore: IPermissionsStore;
  requestService: RequestService;
  authService: AuthService;

  constructor(
    graphqlRequestService: GraphQlRequestService,
    permissionStore: IPermissionsStore,
    requestService: RequestService,
    authService: AuthService
  ) {
    this.graphQlRequestService = graphqlRequestService;
    this.permissionStore = permissionStore;
    this.requestService = requestService;
    this.authService = authService;
  }

  public async executeCustomSqlQuery(
    domain: string,
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    queryString: string,
    disableNestLoop?: boolean
  ): Promise<ApiMasterDataQueryResponse> {
    const queryDataLoader = this.getQueryDataLoader(domain);
    return (
      await queryDataLoader.load({
        queryType: ApiQueryTypes.SQL,
        query: { querySql: queryString, disableNestLoop },
        processResponse: this.toSqlQueryResponse(dimensions, measures),
      })
    ).response;
  }

  private toSqlQueryResponse =
    (dimensions: ApiMasterDataAdvancedQueryDimension[], measures: ApiMasterDataQueryMeasure[]) =>
    (result: SqlQueryResponse): ApiMasterDataQueryResponse => {
      const rows = result?.data;
      return {
        dataPoints:
          rows?.map((row) =>
            row.reduce(
              (acc, value, index) => {
                const parsedValue = parseResponseValue(value as CustomSqlQueryValueBackendType);
                if (dimensions[index]) {
                  const dim = dimensions[index];
                  const dataType = dim.dataType;
                  const dataField = dim.property;
                  acc['dimensions'].push({
                    dataType: dataType as ApiMasterDataTypes,
                    property: dataField as ApiMasterDataField,
                    value: parsedValue,
                  });
                } else if (measures[index - dimensions.length]) {
                  const measure = measures[index - dimensions.length];
                  acc['measures'].push({
                    dataType: measure.dataType as ApiMasterDataTypes,
                    property: measure.property as ApiMasterDataField,
                    value: parsedValue,
                    operation: measure.operation,
                  });
                } else {
                  throw new Error('Missing dimension or measure');
                }
                return acc;
              },
              { dimensions: [], measures: [] } as ApiCustomSqlQueryResponseDataPoint
            )
          ) ?? [],
      };
    };

  private domainToDataLoaderMap: Record<string, DataLoader<QueryInputType, ApiMasterDataBatchQueryResponseItem>> = {};

  private getQueryDataLoader = (domain: string) => {
    if (this.domainToDataLoaderMap[domain]) {
      return this.domainToDataLoaderMap[domain];
    } else {
      const batchFn = async (keys: readonly QueryInputType[]) => {
        // This fails if any of the queries fail, returning 206
        // See this discussion https://panalyt.slack.com/archives/C020B3RUR9U/p1717389224240879
        return (await this.executeGraphqlQueriesInBatch(domain, [...keys])).responseListItems;
      };
      const dataLoader = new DataLoader<QueryInputType, ApiMasterDataBatchQueryResponseItem>((keys) => batchFn(keys), {
        cache: false,
        // We should enable cache eventually and maybe replace our in memory cache with this
        // One benefit of using data loader cache is that it comes with deduplication
        // However, when we enable cache, we might need to provide a custom cache as the default one
        // uses reference equality check for object keys
        maxBatchSize: 100,
        // We tried out different batch sizes and 100 seems to be fine. Less is causing
        // error due to too many requests. Although we can still continue experimenting
        // and refine the batch size for further optimization. This might also depend on
        // backend and db configurations
      });
      this.domainToDataLoaderMap[domain] = dataLoader;
      return this.domainToDataLoaderMap[domain];
    }
  };

  public async executeMovementQuery(
    domain: string,
    query: ApiMasterDataMovementQuery
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);
    const queryDataLoader = this.getQueryDataLoader(domain);
    return (
      await queryDataLoader.load({
        queryType: ApiQueryTypes.MOVEMENT,
        query,
        processResponse: (r: string | null) => (r ? (JSON.parse(r) as ApiMasterDataQueryResponse) : { dataPoints: [] }),
      })
    ).response;
  }

  private customSqlQueryExecutorFieldSelection = `{ data {
    __typename
    ... on BigDecimalValue {
      bigDecimal: value
    }
    ... on BooleanValue {
      boolean: value
    }
    ... on DateValue {
      date: value
    }
    ... on DoubleValue {
      double: value
    }
    ... on IntValue {
      int: value
    }
    ... on StringValue {
      string: value
    }
    ... on TimestampValue {
      timestamp: value
    }
    ... on JsonValue {
      json: value
    }
    ... on Confidential {
      reason
    }
   } }`;

  private async executeGraphqlQueriesInBatch(
    domain: string,
    queries: QueryInputType[]
  ): Promise<ApiMasterDataBatchQueryResponse> {
    const graphQlClient = this.graphQlRequestService.graphQlClientBatching;
    if (!graphQlClient) {
      return Promise.reject('GraphQlClient is not initialized');
    }
    const selectedExecutorRole = `"${this.permissionStore.getExecutorRole().id}"`;
    const simulateRoleId = this.permissionStore.currentlySimulatingRole()?.id;
    const simulateRole = simulateRoleId ? `"${simulateRoleId}"` : null;

    const graphQlQueries = queries.reduce<{
      [ApiQueryTypes.MOVEMENT]: Record<
        string,
        { queryString: string; processResponse: (result: string) => ApiMasterDataQueryResponse }
      >;
      [ApiQueryTypes.SQL]: Record<
        string,
        { queryString: string; processResponse: (result: SqlQueryResponse) => ApiMasterDataQueryResponse }
      >;
    }>(
      (acc, q, i) => {
        const queryAlias = `query${i}`;
        return match(q)
          .with({ queryType: ApiQueryTypes.MOVEMENT }, (queryInput) => {
            const query = queryInput.query;
            const disableNestLoop = query.disableNestLoop ?? false;
            const graphQlQuery = `${ApiQueryTypes.MOVEMENT}(domain:"${domain}", queryJson:"""${JSON.stringify(
              query
            )}""", selectedExecutorRole: ${selectedExecutorRole}, simulateRole: ${simulateRole}, disableNestLoop: ${disableNestLoop})`;
            return {
              ...acc,
              [ApiQueryTypes.MOVEMENT]: {
                ...acc[ApiQueryTypes.MOVEMENT],
                [queryAlias]: { queryString: graphQlQuery, processResponse: queryInput.processResponse },
              },
            };
          })
          .with({ queryType: ApiQueryTypes.SQL }, (queryInput) => {
            const query = queryInput.query;
            const querySql = query.querySql;
            const disableNestLoop = query.disableNestLoop ?? false;
            const graphQlQuery = `${ApiQueryTypes.SQL}(domain:"${domain}", querySql: """${querySql} """, selectedExecutorRole: ${selectedExecutorRole}, simulateRole: ${simulateRole}, disableNestLoop: ${disableNestLoop}) ${this.customSqlQueryExecutorFieldSelection}`;
            return {
              ...acc,
              [ApiQueryTypes.SQL]: {
                ...acc[ApiQueryTypes.SQL],
                [queryAlias]: { queryString: graphQlQuery, processResponse: queryInput.processResponse },
              },
            };
          })
          .exhaustive();
      },
      { [ApiQueryTypes.MOVEMENT]: {}, [ApiQueryTypes.SQL]: {} }
    );

    const responses: ApiMasterDataBatchQueryResponseItem[] = [];
    if (Object.keys(graphQlQueries[ApiQueryTypes.MOVEMENT]).length > 0) {
      const graphQlQueriesWithAlias = Object.entries(graphQlQueries[ApiQueryTypes.MOVEMENT]);
      const queries = `query { ${graphQlQueriesWithAlias.reduce(
        (acc, [alias, q]) => acc + `${alias}:${q.queryString} `,
        ''
      )} }`;
      const movementQueryResults = await graphQlClient.request<Record<string, string>>(queries);
      responses.push(
        ...graphQlQueriesWithAlias.flatMap(([alias, query]) => {
          const response = movementQueryResults[alias];
          if (response) {
            const processedResult = query.processResponse(response);
            return { query: query.queryString, response: processedResult };
          } else {
            console.error(
              `Did not expect alias ${alias} in graphql response. There is mismatch in the sent queries and the response. Skipping this query: ${query}`
            );
            return [];
          }
        })
      );
    }
    if (Object.keys(graphQlQueries[ApiQueryTypes.SQL]).length > 0) {
      const graphQlQueriesWithAlias = Object.entries(graphQlQueries[ApiQueryTypes.SQL]);
      const queries = `query { ${graphQlQueriesWithAlias.reduce(
        (acc, [alias, q]) => acc + `${alias}:${q.queryString} `,
        ''
      )} }`;
      const sqlQueryResults = await graphQlClient.request<Record<string, SqlQueryResponse>>(queries);
      responses.push(
        ...graphQlQueriesWithAlias.flatMap(([alias, query]) => {
          const response = sqlQueryResults[alias];
          if (response) {
            const processedResult = query.processResponse(response);
            return [{ query: query.queryString, response: processedResult }];
          } else {
            console.error(
              `Did not expect alias ${alias} in graphql response. There is mismatch in the sent queries and the response. Skipping this query: ${query}`
            );
            return [];
          }
        })
      );
    }
    return { responseListItems: responses };
  }

  public async queryCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    userFilters?: SQLFilters,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .queryCohortMetrics({
          domain,
          metrics,
          disableNestLoop: disableNestLoop ?? null,
          timeSelection,
          userFilters: userFilters ?? null,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const results = result.queryCohortMetrics?.results;

          const failures = results?.filter((r) => r.__typename === 'MetricResultFailure') as MetricResultFailure[];
          const successes = results?.filter((r) => r.__typename === 'MetricResultSuccess') as MetricResultSuccess[];

          return (
            successes?.reduce((acc, r) => {
              const dataPoints = r.segments.flatMap((s) => {
                const res = s.groupSegments.map((gs) => {
                  const dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[] = this.parseTimeSegment(
                    s.timeSegment
                  );
                  const measures: ApiCustomSqlQueryResponseDataPointMeasureData[] = [];
                  measures.push({
                    dataType: DataTypes.EMPLOYEE, //TODO: fix
                    operation: Operations.EQUAL as ApiMasterDataQueryMeasureOperation,
                    property: r.metricId as unknown as ApiMasterDataField, //TODO: fix
                    value: parseCustomSqlValue(gs.data),
                  });
                  dimensions.push(
                    ...gs.groupSegment.map(
                      (gss) =>
                        ({
                          property: gss.dataField as DataFields,
                          dataType: gss.dataType as DataTypes,
                          value: parseCustomSqlValue(gss.value),
                        } as ApiCustomSqlQueryResponseDataPointDimensionData)
                    )
                  );
                  return { dimensions, measures, meta: r.meta };
                });
                return res;
              });
              acc = { dataPoints: [...(acc?.dataPoints ?? []), ...(dataPoints ?? [])] };
              return acc;
            }, {} as ApiMetricQueryResponse) ?? ({} as ApiMetricQueryResponse)
          );
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  public async queryEmployeeCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: EmployeeCohortMetricId[],
    userFilters?: SQLFilters,
    userCohortFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .queryEmployeeCohortMetrics({
          domain,
          metrics,
          disableNestLoop: disableNestLoop ?? null,
          grouping: grouping ?? null,
          timeSelection,
          userFilters: userFilters ?? null,
          userCohortFilters: userCohortFilters ?? null,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const results = result.queryEmployeeCohortMetrics?.results;

          const failures = results?.filter((r) => r.__typename === 'MetricResultFailure') as MetricResultFailure[];
          const successes = results?.filter((r) => r.__typename === 'MetricResultSuccess') as MetricResultSuccess[];

          return (
            successes?.reduce((acc, r) => {
              const dataPoints = r.segments.flatMap((s) => {
                const res = s.groupSegments.map((gs) => {
                  const dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[] = this.parseTimeSegment(
                    s.timeSegment
                  );
                  const measures: ApiCustomSqlQueryResponseDataPointMeasureData[] = [];
                  measures.push({
                    dataType: DataTypes.EMPLOYEE, //TODO: fix
                    operation: Operations.EQUAL as ApiMasterDataQueryMeasureOperation,
                    property: r.metricId as unknown as ApiMasterDataField, //TODO: fix
                    value: parseCustomSqlValue(gs.data),
                  });
                  dimensions.push(
                    ...gs.groupSegment.map(
                      (gss) =>
                        ({
                          property: gss.dataField as DataFields,
                          dataType: gss.dataType as DataTypes,
                          value: parseCustomSqlValue(gss.value),
                        } as ApiCustomSqlQueryResponseDataPointDimensionData)
                    )
                  );
                  return { dimensions, measures, meta: r.meta };
                });
                return res;
              });
              acc = { dataPoints: [...(acc?.dataPoints ?? []), ...(dataPoints ?? [])] };
              return acc;
            }, {} as ApiMetricQueryResponse) ?? ({} as ApiMetricQueryResponse)
          );
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  public async queryMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    userFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .queryMetrics({
          domain,
          metrics,
          disableNestLoop: disableNestLoop ?? null,
          grouping: grouping ?? null,
          timeSelection,
          userFilters: userFilters ?? null,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const results = result.queryMetrics?.results;

          const failures = results?.filter((r) => r.__typename === 'MetricResultFailure') as MetricResultFailure[];
          const successes = results?.filter((r) => r.__typename === 'MetricResultSuccess') as MetricResultSuccess[];

          return (
            successes?.reduce((acc, r) => {
              const dataPoints = r.segments.flatMap((s) => {
                const res = s.groupSegments.map((gs) => {
                  const dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[] = this.parseTimeSegment(
                    s.timeSegment
                  );
                  const measures: ApiCustomSqlQueryResponseDataPointMeasureData[] = [];
                  measures.push({
                    dataType: DataTypes.EMPLOYEE, //TODO: fix
                    operation: Operations.EQUAL as ApiMasterDataQueryMeasureOperation,
                    property: r.metricId as unknown as ApiMasterDataField, //TODO: fix
                    value: parseCustomSqlValue(gs.data),
                  });
                  dimensions.push(
                    ...gs.groupSegment.map(
                      (gss) =>
                        ({
                          property: gss.dataField as DataFields,
                          dataType: gss.dataType as DataTypes,
                          value: parseCustomSqlValue(gss.value),
                        } as ApiCustomSqlQueryResponseDataPointDimensionData)
                    )
                  );
                  return { dimensions, measures, meta: r.meta };
                });
                return res;
              });
              acc = { dataPoints: [...(acc?.dataPoints ?? []), ...(dataPoints ?? [])] };
              return acc;
            }, {} as ApiMetricQueryResponse) ?? ({} as ApiMetricQueryResponse)
          );
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  private parseTimeSegment(timeSegment: TimeSegment) {
    if (
      timeSegment.__typename === 'SingleValueTimeSegment' ||
      timeSegment.__typename === 'CalendarYearSingleValueByMonthsSegment'
    ) {
      return [];
    }
    const dimension: ApiCustomSqlQueryResponseDataPointDimensionData = {
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.VERSION_ID,
      value: match(timeSegment)
        .with({ __typename: 'CalendarYearYearlySegment' }, (value) => `${value.year}`)
        .with(
          { __typename: 'CalendarYearQuarterlySegment' },
          (value) => `${value.quarter.year}-${value.quarter.quarterOfYear}`
        )
        .with({ __typename: 'CalendarYearMonthlySegment' }, (value) => value.date)
        .with({ __typename: 'FinancialYearYearlySegment' }, (value) => `${value.year}`)
        .with(
          { __typename: 'FinancialYearQuarterlySegment' },
          (value) => `${value.quarter.year}-${value.quarter.quarterOfYear}`
        )
        .otherwise(() => {
          throw new Error('time segment is invalid');
        }) as string,
    };
    return [dimension];
  }
}

export const cachedMasterDataApiService = new CachedMasterDataService();
