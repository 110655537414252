import moment from 'moment';
import { match, P } from 'ts-pattern';
import { Quarter } from './api/timeranges/types';
import { Granularity, Months, TimeSliderGranularity } from './common/components/timeslider/types';
import { Languages } from './constants';
import { TimestampUTC } from './types';

export const monthStart = (d: TimestampUTC) => moment.utc(d).startOf('month').startOf('day').valueOf();
export const monthEnd = (d: TimestampUTC) => moment.utc(d).endOf('month').startOf('day').valueOf();
export const yearEnd = (d: TimestampUTC) => monthEndUtc(d).endOf('year').startOf('day').valueOf();
export const yearStart = (d: TimestampUTC) => monthEnd(monthEndUtc(d).startOf('year').valueOf());
export const quarterStart = (d: TimestampUTC) => monthEnd(monthEndUtc(d).startOf('quarter').valueOf());

// zero based index
export const monthIndex = (month: Months): number => Object.keys(Months).indexOf(month);

export const fiscalQuarter = (d: TimestampUTC, firstMonthOfYear: Months) => {
  const firstMonthNum = monthIndex(firstMonthOfYear);
  const month = date(d).month() + 1;
  const deltaFromStartMonth = month >= firstMonthNum ? month - firstMonthNum + 1 : 12 - firstMonthNum + month + 1;
  return Math.ceil(deltaFromStartMonth / 3);
};

export const fiscalYearStart = (d: TimestampUTC, firstMonthOfYear: Months) => {
  const firstMonthNum = monthIndex(firstMonthOfYear);
  const dateAsMoment = date(d);
  const month = dateAsMoment.month();
  const year = dateAsMoment.year();

  const fyYear = month < firstMonthNum ? year - 1 : year;
  const fyMonth = firstMonthNum;
  const fyDay = 1;
  const fyDate = dateAt(fyYear, fyMonth, fyDay, d);
  return fyDate.valueOf();
};

export const startForGranularity = (d: TimestampUTC, granularity: TimeSliderGranularity, firstMonthOfYear: Months) => {
  const firstMonthOfYearIndex = Object.keys(Months).indexOf(firstMonthOfYear);
  return match(granularity)
    .with({ value: Granularity.MONTH }, () => monthStart(d))
    .with({ value: Granularity.YEAR }, () => monthStart(yearStart(d)))
    .with({ value: P.union(Granularity.FINQUARTER) }, () =>
      monthStart(addMonth(quarterStart(d), firstMonthOfYearIndex))
    )
    .with({ value: P.union(Granularity.FINYEAR) }, () => monthStart(addMonth(yearStart(d), firstMonthOfYearIndex)))
    .otherwise(() => {
      throw new Error('Unsupported granularity');
    });
};

export const endForGranularity = (d: TimestampUTC, granularity: TimeSliderGranularity, firstMonthOfYear: Months) => {
  const firstMonthOfYearIndex = Object.keys(Months).indexOf(firstMonthOfYear);
  return match(granularity)
    .with({ value: Granularity.MONTH }, () => monthEnd(d))
    .with({ value: Granularity.YEAR }, () => monthEnd(yearStart(d)))
    .with({ value: Granularity.FINQUARTER }, () => monthEnd(addMonth(quarterStart(d), firstMonthOfYearIndex)))
    .with({ value: Granularity.FINYEAR }, () => monthEnd(addMonth(yearStart(d), firstMonthOfYearIndex)))
    .otherwise(() => {
      throw new Error('Unsupported granularity');
    });
};

export const isSame = (d1: TimestampUTC, d2: TimestampUTC) => date(d1).isSame(date(d2), 'day');
export const isBeforeOrSame = (d1: TimestampUTC, d2: TimestampUTC) => date(d1).isSameOrBefore(date(d2), 'day');
export const isBefore = (d1: TimestampUTC, d2: TimestampUTC) => date(d1).isBefore(date(d2), 'day');

export const addDay = (d: TimestampUTC, count: number) => date(d).clone().add(count, 'day').valueOf();
export const removeMonth = (d: TimestampUTC, count: number) => addMonth(d, -count);
export const addMonth = (d: TimestampUTC, count: number) => date(d).clone().add(count, 'month').valueOf();
export const removeQuarter = (d: TimestampUTC, count: number) => addQuarter(d, -count);
export const addQuarter = (d: TimestampUTC, count: number) => addMonth(d, count * 3);
export const removeYear = (d: TimestampUTC, count: number) => addYear(d, -count);
export const addYear = (d: TimestampUTC, count: number) => date(d).clone().add(count, 'year').valueOf();

export const format = (d: TimestampUTC) => date(d).format('YYYY-MM-DD');

export const formatYear = (d: number, locale: Languages): string => {
  switch (locale) {
    case Languages.EN:
      return d.toString();
    case Languages.JA:
      return `${d}年`;
    default:
      throw new Error('Unsupported locale');
  }
};
export const formatFinYear = (d: number, locale: Languages): string => {
  switch (locale) {
    case Languages.EN:
      return `FY${d}`;
    case Languages.JA:
      return `FY${d}`;
    default:
      throw new Error('Unsupported locale');
  }
};
export const formatFinQuarter = (d: Quarter, locale: Languages): string => {
  switch (locale) {
    case Languages.EN:
      return `${formatFinYear(d.year, locale)} Q${d.quarterOfYear}`;
    case Languages.JA:
      return `${formatFinYear(d.year, locale)} Q${d.quarterOfYear}`;
    default:
      throw new Error('Unsupported locale');
  }
};

export const formatMonth = (d: Date, locale: Languages) => {
  switch (locale) {
    case Languages.EN:
      return date(d).format(`MMM 'YY`);
    case Languages.JA:
      return date(d).format(`'YY年M月`);
    default:
      throw new Error('Unsupported locale');
  }
};

export const monthEndUtc = (d: TimestampUTC) => moment.utc(monthEnd(d));
export const now = () => {
  return moment.utc().valueOf();
};
export const date = (s: string | TimestampUTC | Date) => {
  return moment.utc(s);
};
export const dateAt = (year: number, month: number, day: number, current: TimestampUTC = now()) =>
  date(current).year(year).month(month).date(day);
