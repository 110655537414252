import { addMonth, addYear, date, format, monthEnd, now } from '../../utils-date';

// TODO: review this as we won't be able to store logic in json settings
// we should have two options: relative (e.g. last 3 months) or absolute timeframes (e.g. 2023 to 2024)
export const xYearsAgo = (x: number) => addYear(now(), -x);
export const lastYear = addYear(now(), -1);
export const lastYearEndOfMonth = format(monthEnd(lastYear));
export const nowEndOfMonth = format(monthEnd(now()));
export const lastMonthEndOfMonth = format(monthEnd(addMonth(now(), -1)));
export const startOfYear = format(date(now()).startOf('year').valueOf());
export const startOfLastYear = format(date(lastYear).startOf('year').valueOf());
