import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardId } from '../../common/components/dashboards/types';
import {
  useDomainViewDashboardServiceContext,
  useFullViewDashboardServiceContext,
  useUserViewDashboardServiceContext,
} from '../../context/contexts';
import { DashboardService } from './dashboard-service';

const useGetUserViewCustomDashboardConfigKey = ['useGetUserViewCustomDashboardConfigKey'];
const useListUserViewCustomDashboardConfigsKey = ['useListUserViewCustomDashboardConfigsKey'];
const useListFullViewDashboardsQueryKey = ['useListFullViewDashboardsQueryKey'];
const useListDomainViewDashboardsQueryKey = ['useListDomainViewDashboardsQueryKey'];
const useListUserViewDashboardsQueryKey = ['useListUserViewDashboardsQueryKey'];

export const useListUserViewCustomDashboardConfigs = () => {
  const dashboardService = useUserViewDashboardServiceContext();
  const { data: userViewCustomDashboardConfigsList } = useSuspenseQuery({
    queryKey: useListUserViewCustomDashboardConfigsKey,
    queryFn: async () => {
      return dashboardService.listCustomDashboardConfigs();
    },
  });
  return userViewCustomDashboardConfigsList;
};

export const useGetUserViewCustomDashboardConfig = (dashboardId: DashboardId) => {
  const dashboardService = useUserViewDashboardServiceContext();
  const { data: userViewCustomDashboardConfig } = useSuspenseQuery({
    queryKey: useGetUserViewCustomDashboardConfigKey,
    queryFn: async () => {
      return dashboardService.getCustomDashboardConfig(dashboardId);
    },
  });
  return userViewCustomDashboardConfig;
};

const useListDashboards = (dashboardService: DashboardService, queryKey: any[]) => {
  const { data: dashboards } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return dashboardService.listDashboards();
    },
  });
  return dashboards;
};

export const useListFullViewDashboards = () => {
  const dashboardService = useFullViewDashboardServiceContext();
  return useListDashboards(dashboardService, useListFullViewDashboardsQueryKey);
};

export const useListDomainViewDashboards = () => {
  const dashboardService = useDomainViewDashboardServiceContext();
  return useListDashboards(dashboardService, useListDomainViewDashboardsQueryKey);
};

export const useListUserViewDashboards = () => {
  const dashboardService = useUserViewDashboardServiceContext();
  return useListDashboards(dashboardService, useListUserViewDashboardsQueryKey);
};
