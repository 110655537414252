import LogoutIcon from '@mui/icons-material/Logout';
import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { Redirect, Switch } from 'react-router';
import whiteLogoUrl from '../../assets/image/panalyt_logo_white.png';
import { FixedWidthGap } from '../common/components/layout';
import { PrivateRoute } from '../common/components/routing/private-route';
import LanguageSwitcher from '../common/components/shell/LanguageSwitcher';
import { Header, Logo, StyledAppBar } from '../common/components/shell/Shell';
import { dataUploadRoute } from '../common/routes';
import { rootStore } from '../common/store/root-store';

export const DataUploadOnlyApp = () => {
  return (
    <Grid container>
      <StyledAppBar>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'relative',
          }}
        >
          <Logo src={whiteLogoUrl} className="intercom_appbar_logo" />
          <Grid
            item
            container
            sx={{
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 0,
            }}
          >
            <LanguageSwitcher />
            <FixedWidthGap $gap="8px" />
            <IconButton
              onClick={() => {
                localStorage.clear();
                rootStore.authStore.logOutUser();
              }}
              style={{ color: 'white' }}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        </Grid>
      </StyledAppBar>
      <main>
        <Header />
        <Switch>
          <PrivateRoute key={dataUploadRoute.id} {...dataUploadRoute} />
          <Redirect from="/" to={dataUploadRoute.path} />
        </Switch>
      </main>
    </Grid>
  );
};
