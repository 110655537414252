import { SQLFilters } from '../../../api/types';
import {
  MetricIdTypeArrays,
  OverTimeTimeSelection,
  RegularMetricIdType,
  Segmentation,
  SingleValueTimeSelection,
} from '../../../types';
import { CustomChartWrapper } from '../charts/custom-chart-components/custom-chart-types';
import { Languages } from '../../../constants';

export type ChartTypeConfig =
  | TableConfig
  | AreaChartConfig
  | LineChartConfig
  | BarChartConfig
  | PieChartConfig
  | KPIConfig;

export interface OverTimeTableConfig {
  chartType: 'table';
  timeframe: 'overTime';
  title?: string;
}

export type TableConfig = OverTimeTableConfig;

export interface OverTimeBarChartConfig {
  chartType: 'bar';
  timeframe: 'overTime';
  title?: string;
  stack?: boolean;
  relative?: boolean;
}

export interface TimePeriodBarChartConfig {
  chartType: 'bar';
  timeframe: 'timePeriod';
  title?: string;
  stack?: boolean;
  relative?: boolean;
}

export type BarChartConfig = TimePeriodBarChartConfig | OverTimeBarChartConfig;

export interface LineChartConfig {
  chartType: 'line';
  title?: string;
}

export interface PieChartConfig {
  chartType: 'pie';
  variant?: 'donut' | 'pie';
}

export interface KPIConfig {
  chartType: 'kpi';
}

export interface AreaChartConfig {
  chartType: 'area';
  title?: string;
  stack?: boolean;
}

export interface CustomChartConfig {
  chartType: 'custom';
}

type GridColSpan = number;

type GridRowSpan = number;

type GridCols = number;

type GridRows = number;

type GridColStart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridColEnd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridRowStart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridRowEnd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type LanguageString = Record<Languages, string>;

export interface OverTimeTableQueryConfig {
  metrics: RegularMetricIdType[];
  timeSelection: OverTimeTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface LineChartQueryConfig {
  metrics: MetricIdTypeArrays;
  timeSelection: OverTimeTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface ChartGridConfig {
  gridColSpan?: GridColSpan;
  gridRowSpan?: GridRowSpan;
  gridColStart?: GridColStart;
  gridColEnd?: GridColEnd;
  gridRowStart?: GridColStart;
  gridRowEnd?: GridColEnd;
}

export interface BaseConfig {
  title?: LanguageString;
  subtitle?: LanguageString;
  chartId: string;
  classNames?: string;
}

export interface TableComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: TableConfig;
  queries: OverTimeTableQueryConfig[];
}

export interface LineChartComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: LineChartConfig | AreaChartConfig;
  colors?: string[];
  queries: LineChartQueryConfig[];
}

export interface CustomChartComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: CustomChartConfig;
  customComponent: CustomChartWrapper;
}

export interface TimePeriodBarChartQueryConfig {
  metrics: RegularMetricIdType[];
  timeSelection: SingleValueTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface TimePeriodBarChartComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: TimePeriodBarChartConfig;
  colors?: string[];
  queries: TimePeriodBarChartQueryConfig[];
}

export interface OverTimeBarChartQueryConfig {
  metrics: MetricIdTypeArrays;
  timeSelection: OverTimeTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface OverTimeBarChartComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: OverTimeBarChartConfig;
  colors?: string[];
  queries: OverTimeBarChartQueryConfig[];
}

export type BarChartComponentConfig = TimePeriodBarChartComponentConfig | OverTimeBarChartComponentConfig;

interface PieChartQueryConfig {
  metrics: RegularMetricIdType[];
  timeSelection: SingleValueTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface PieChartComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: PieChartConfig;
  colors?: string[];
  query: PieChartQueryConfig;
}

export interface KpiQueryConfig {
  metrics: RegularMetricIdType[];
  timeSelection: SingleValueTimeSelection;
  filters?: SQLFilters;
  segmentations?: Segmentation[];
}

export interface KpiComponentSectionConfig {
  key: string;
  title?: LanguageString;
  subtitle?: LanguageString;
  colors?: string[];
  queries: KpiQueryConfig[];
}

export interface KpiComponentConfig extends ChartGridConfig, BaseConfig {
  chartTypeConfig: KPIConfig;
  sections: KpiComponentSectionConfig[];
  align?: 'column' | 'row';
}

export type ComponentConfig =
  | TableComponentConfig
  | KpiComponentConfig
  | PieChartComponentConfig
  | BarChartComponentConfig
  | LineChartComponentConfig
  | CustomChartComponentConfig;

export interface PageConfig {
  pageId: string;
  dashboardId: DashboardId;
  pageNumber: number;
  enabled: boolean;
  title: LanguageString;
  pageOptions?: DashboardPageOptions;
  components: ComponentConfig[];
  gridCols?: GridCols;
  gridRows?: GridRows;
}

type EnabledOption = {
  enabled?: boolean;
};
export interface DashboardPageOptions {
  timeSlider?: EnabledOption & {};
  dataView?: EnabledOption & {};
  filterTray?: EnabledOption & {
    myFilters?: EnabledOption;
  };
  segmentationLevel1?: EnabledOption & {};
  segmentationLevel2?: EnabledOption & {};
  benchmark?: EnabledOption & {};
  forecast?: EnabledOption & {};
  displayOptions?: EnabledOption & {};
}

export type DashboardId = string;

export interface DashboardConfig {
  title: LanguageString;
  id: DashboardId;
  enabled: boolean;
  pages: PageConfig[];
}

export interface PageHandle {
  selected: PageConfig;
  pages: PageConfig[];
  setter: (page: PageConfig) => void;
}

export interface ApiDashboard {
  id: Dashboards;
  nameKey: string;
  descriptionKey: string;
  enabled: boolean;
  imageLink: string;
  link: string;
  pages: ApiDashboardPage[];
}

export interface ApiDashboardPage {
  pageId: string;
  dashboardId: Dashboards;
  pageNumber: number;
  nameKey: string;
  descriptionKey: string;
  enabled: boolean;
  imageLink: string;
  link: string;
}

export interface ApiDashboardList {
  dashboards: ApiDashboard[];
}

export enum Dashboards { // Note: the actual values should be ids as in the backend config for these dashboards
  ATTRITION = 'attrition',
  EVALUATION = 'evaluation',
  GENDER_DIVERSITY = 'genderDiversity',
  JOINERS_AND_LEAVERS = 'joinersAndLeavers',
  MEDIAN_BASE = 'medianBase',
  MOVEMENTS = 'movement',
  PAYROLL = 'payroll',
  PEOPLE_BALANCE_SHEET = 'peopleBalanceSheet',
  RECRUITMENT_BALANCE_SHEET = 'recruitmentBalanceSheet',
  PEOPLE_SNAPSHOT = 'peopleSnapshot',
  RECRUITMENT_FUNNEL = 'recruitmentFunnel',
  RECRUITMENT_OVERVIEW = 'recruitment',
  SURVEY = 'survey',
  WORK_HOURS_AND_OT = 'workHoursAndOt',
}

// The ids should not be changed, ideally they should come from the backend
// so we can ensure that they're the same
export enum DashboardPages {
  ATTRITION_OVERVIEW = 'attrition-overview',
  GENDER_DIVERSITY_DEMOGRAPHICS = 'gender-diversity-demographics',
  GENDER_DIVERSITY_ATTRITION = 'gender-diversity-attrition',
  JOINERS_AND_LEAVERS_OVERVIEW = 'joiners-and-leavers-overview',
  COMPANY_STRUCTURE_ORGANIZATIONS = 'company-structure-organizations',
  COMPANY_STRUCTURE_LOCATIONS = 'company-structure-locations',
  COMPANY_STRUCTURE_JOB_GRADES = 'company-structure-job-grades',
  COMPANY_STRUCTURE_FUNCTIONS = 'company-structure-functions',
  COMPANY_STRUCTURE_EMPLOYMENT_TYPES = 'company-structure-employment-types',
  MEDIAN_BASE_OVERVIEW = 'median-base-overview',
  MEDIAN_BASE_KEY_METRICS = 'median-base-key-metrics',
  MEDIAN_BASE_ICR_DISTRIBUTION = 'median-base-icr-distribution',
  ONA_TRAINS_GRAPH = 'ona-trains-graph',
  RECRUITMENT_FUNNEL_OVERVIEW = 'recruitment-funnel-overview',
  PAYROLL_COST_TO_COMPANY = 'payroll-cost-to-company',
  PAYROLL_COST_TO_EMPLOYEE = 'payroll-cost-to-employee',
  SNAPSHOT_HEADCOUNT = 'snapshot-headcount',
  SNAPSHOT_KPIS = 'snapshot-kpis',
  SNAPSHOT_ORG_METRICS = 'snapshot-org-metrics',
  RBS_OVERVIEW = 'rbs-overview',
  RBS_METRICS_OVER_TIME = 'rbs-metrics-over-time',
  RBS_ISO_DEFINITIONS = 'rbs-iso-definitions',
  RELATIONSHIP_TO_CLIENTS_OVERVIEW = 'relationship-to-clients-overview',
  RELATIONSHIP_TO_CLIENTS_SINGLE_CLIENT = 'relationship-to-clients-single-client',
  RELATIONSHIP_TO_CLIENTS_OVER_TIME = 'relationship-to-clients-over-time',
  WORKHOURS_AND_OT_WORKHOURS = 'workhours-and-ot-workhours',
  WORKHOURS_AND_OT_OVERTIME = 'workhours-and-ot-overtime',
  PBS_OVERVIEW = 'pbs-overview',
  PBS_METRICS_OVER_TIME = 'pbs-metrics-over-time',
  MOVEMENT_OVERVIEW = 'movement-overview',
  MOVEMENT_NO_MOVEMENTS = 'movement-no-movements',
  RECRUITMENT_OVERVIEW = 'recruitment-overview',
  RECRUITMENT_ACTIVITIES = 'recruitment-activities',
  ONA_BS_OVERVIEW = 'ona-bs-overview',
  ONA_METRICS_OVER_TIME = 'ona-metrics-over-time',
  SURVEY_OVERALL_QUESTIONS = 'survey-overall-questions',
  SURVEY_SENTIMENT = 'survey-sentiment',
  ONA_GRAPH = 'ona-graph',
  ONA_GRAVITY = 'ona-gravity',
  EVALUATION_OVERVIEW = 'evaluation-overview',
  MULTICYCLE_EVALUATION = 'multicycle-evaluation',
  ATTRITION_RISK_OVERVIEW = 'attrition-risk-overview',
  ATTRITION_RISK_EMPLOYEES = 'attrition-risk-employees',
  ATTRITION_RISK_MANAGERS = 'attrition-risk-managers',
}
