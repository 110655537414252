import { action, observable, when } from 'mobx';
import { getAttritionTranslationKey } from '../../pages/dashboard/dashboard-custom-translations';
import { ApiUser } from '../api/api-interfaces';
import { GraphQlRequestService } from '../api/graphql-request-service';
import { getErrorCodeFromError, getGraphqlResult, GraphQlRequestErrorCode } from '../api/utils';
import { FrontendStartupQuery } from '../graphql/generated/graphql-sdk';
import { trackAppLogin } from '../helpers/trackers/userTracker';
import { DomainInfo } from '../v2/api/types';
import { ApiDashboard, Dashboards } from '../v2/common/components/dashboards/types';

// Not using an interface because of direct usage of graphql types, making it almost impossible to define the interface types

export enum InitialDependenciesErrors {
  GENERAL_INITIAL_DEPENDENCIES_ERROR = 'GENERAL_INITIAL_DEPENDENCIES_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  USER_NOT_FOUND_ERROR = 'USER_NOT_FOUND_ERROR',
  WRONG_AUTH_METHOD_ERROR = 'WRONG_AUTH_METHOD_ERROR',
}

export class InitialDependencyStore {
  private graphQlRequestService: GraphQlRequestService;

  @observable
  public frontendStartupData!: FrontendStartupQuery;

  @observable
  public isFrontendStartupDataFetched: boolean = false;

  @observable
  public couldNotFetchFrontendStartupData: InitialDependenciesErrors | null = null;

  public initialDependenciesReady = () => {
    return this.isFrontendStartupDataFetched;
  };

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;

    when(
      () => this.graphQlRequestService.graphQlClient != null,
      () => {
        this.loadInitialDependencies().catch((error) => {
          const errorMessage: string = error.message ?? '';
          const errorCode = getErrorCodeFromError(error);
          const networkRequestFailedRegex = /Network request failed/i;
          if (networkRequestFailedRegex.test(errorMessage)) {
            this.couldNotFetchFrontendStartupData = InitialDependenciesErrors.NETWORK_ERROR;
          } else if (errorCode === GraphQlRequestErrorCode.Err_UserNotFound) {
            this.couldNotFetchFrontendStartupData = InitialDependenciesErrors.USER_NOT_FOUND_ERROR;
          } else if (errorCode === GraphQlRequestErrorCode.Err_DomainAccessDeniedWrongAuthMethod) {
            this.couldNotFetchFrontendStartupData = InitialDependenciesErrors.WRONG_AUTH_METHOD_ERROR;
          } else {
            // Optimally we should check the errorCode of the backend to see for what reason it failed
            this.couldNotFetchFrontendStartupData = InitialDependenciesErrors.GENERAL_INITIAL_DEPENDENCIES_ERROR;
          }
        });
      }
    );
  }

  @action
  public async loadInitialDependencies() {
    const result = await this.graphQlRequestService.graphQlSdk.frontendStartup();
    this.frontendStartupData = result;
    this.isFrontendStartupDataFetched = true;
    trackAppLogin(result.currentUser?.email);
  }

  public getLatestUserRolePreferences() {
    return getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).preferencesLatestVersion;
  }

  public getExecutorRoles() {
    return getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).executorRoles;
  }

  public getAllowedDomainsWithLatestSettingsAndPreferences(): DomainInfo[] {
    return getGraphqlResult(
      getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).allowedDomains
    );
  }

  public getUserRoleId() {
    return getGraphqlResult(this.frontendStartupData.currentUser).roleId;
  }

  public getUser() {
    const apiUser: ApiUser = { email: getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).email) };
    return apiUser;
  }

  public getAllPermissionsForCurrentUser() {
    return getGraphqlResult(
      getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).allPermissions
    );
  }

  public listAllDashboards() {
    const allDashboards = getGraphqlResult(this.frontendStartupData.listAllDashboards);
    const modifiedAllDashboards: ApiDashboard[] = allDashboards.map((dashboard) => {
      return {
        id: dashboard.id as Dashboards, // TODO errorhandling
        nameKey:
          dashboard.id === Dashboards.ATTRITION ? getAttritionTranslationKey(dashboard.nameKey) : dashboard.nameKey,
        descriptionKey: dashboard.descriptionKey,
        enabled: dashboard.enabled,
        imageLink: dashboard.imagePath,
        link: dashboard.link,
        pages: dashboard.pages.map((page) => {
          return {
            pageId: page.pageId,
            dashboardId: dashboard.id as Dashboards,
            pageNumber: page.pageNumber,
            nameKey:
              page.pageId === 'attrition-overview' || page.pageId === 'gender-diversity-attrition'
                ? getAttritionTranslationKey(page.nameKey)
                : page.nameKey,
            descriptionKey: page.descriptionKey,
            enabled: page.enabled,
            imageLink: page.imagePath,
            link: page.link,
          };
        }),
        connectMoreToolsLink: '',
      };
    });
    return { dashboards: modifiedAllDashboards };
  }
}
