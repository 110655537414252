import { CustomError } from '../../common/errors';

export class DashboardNotFoundError extends CustomError {
  constructor(dashboardId: string) {
    const name = 'DashboardNotFoundError';
    const message = `Dashboard with id: ${dashboardId} Not Found`;
    super(name, message);
  }
}

export class DashboardNotAllowedError extends CustomError {
  constructor(dashboardId: string) {
    const name = 'DashboardNotAllowedError';
    const message = `Dashboard with id: ${dashboardId} Not Allowed for given executor role`;
    super(name, message);
  }
}
