import groupBy from 'lodash.groupby';
import partition from 'lodash.partition';
import { parse, toSql } from 'pgsql-ast-parser/lib';
import * as ast from 'pgsql-ast-parser/lib/syntax/ast';
import { match } from 'ts-pattern';
import { v1 } from 'uuid';
import { QueryToSqlHelper } from '../../api/query-to-sql-helper';
import { JobDataFields } from '../../constants/constants';
import { isHierarchical } from '../api/utils';
import {
  ApplicationDataFields,
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EmployeeDataFields,
  HierarchicalValueTypes,
  MetricIdType,
  OfferDataFields,
  Segmentation,
} from '../types';
import { getKeyFromDataFieldWithDataType, toDataFieldWithDataType } from '../utils';
import { Filter, FilterTypes } from './components/filter/filterbar/types';
import {
  applicationCurrentStageValuesTranslationMap,
  applicationStatusValuesTranslationMap,
  genderTranslationKeyMap,
  hireStatusValuesTranslationMap,
  jobStatusValuesTranslationMap,
  managerOrICTranslationKeyMap,
  maritalStatusTranslationKeyMap,
  regionalLocalTranslationKeyMap,
  regretNonRegretTranslationKeyMap,
  voluntaryNonVoluntaryTranslationKeyMap,
} from './translations';
import {
  ApplicationCurrentStateValues,
  ApplicationStatusValues,
  GenderValues,
  HireStatusValues,
  JobStatusValues,
  ManagerOrIcValues,
  MartialStatusValues,
  RegionalOrLocalValues,
  RegretNonRegretValues,
  SortTypes,
  VoluntaryInvoluntaryValues,
} from './types';

export const uuid = () => v1();

export const getFilterConditions = <T extends FilterTypes>(filters: T[]) => {
  const filtersAST = filtersToAst(filters);
  const result = filtersAST ? toSql.expr(filtersAST) : null;
  return result;
};

const mapNormalFiltersToEmployeeCohortFilters = <T extends FilterTypes>(filter: T): T => {
  const { operation, property, values, dataType } = filter;
  let value: string;
  if (isHierarchical(toDataFieldWithDataType(dataType, property))) {
    const hierarchicalValues = values[0] as HierarchicalValueTypes;
    const empCohortValueObject = Object.fromEntries(
      hierarchicalValues.map((v, index) => {
        return [`${property}_LEVEL_${index + 1}`, v];
      })
    );
    value = JSON.stringify(empCohortValueObject);
  } else {
    value = JSON.stringify({ [property]: values[0] });
  }
  const empCohortFilter: T = {
    ...filter,
    dataType: 'COHORT',
    operation, // need to think if not equals also maps correctly
    property: 'COHORT_VALUE',
    values: [value],
  };
  return empCohortFilter;
};

export const getFilterConditionsEmpCohort = <T extends FilterTypes>(filters: T[]) => {
  const emptyCohortFilter = `COHORT.COHORT_VALUE = '{"" : ""}'`;
  if (!filters.length) {
    return emptyCohortFilter;
  } else {
    const filtersGroupedByDataField = groupBy(filters, (f) => f.property);
    const filterConditionForEachFilterProperty = Object.values(filtersGroupedByDataField).map((filters) => {
      const empCohortMappedFiltersForProperty = filters.map(mapNormalFiltersToEmployeeCohortFilters);
      const filterConditionsForProperty = getFilterConditions(empCohortMappedFiltersForProperty);
      return filterConditionsForProperty;
    });
    const filterConditions = filterConditionForEachFilterProperty.filter((f) => f !== null).join(' AND ');
    return filterConditions || null;
  }
};

export const filtersToAst = <T extends FilterTypes>(filters: T[]) => {
  if (filters.length === 0) return undefined;
  const [sqlFilters, regularFilters] = partition(filters, (f) => Boolean(f.sql));
  let sqlFiltersAST: ast.Expr | null = null;
  if (sqlFilters.length > 0) {
    const sqlFiltersGroupedByDimension = groupBy(sqlFilters, (f) =>
      getKeyFromDataFieldWithDataType({ dataType: f.dataType, dataField: f.property as DataFields })
    );
    const sqlFiltersAsString = Object.entries(sqlFiltersGroupedByDimension)
      .map(([, sqlFiltersForDimension]) => {
        return `( ${sqlFiltersForDimension
          .map((f) => f.sql)
          .join(` ${sqlFiltersForDimension[0].sqlOperator ?? 'OR'} `)} )`;
      })
      .join(' AND ');

    sqlFiltersAST = sqlFiltersAsString ? parse(sqlFiltersAsString, 'expr') : null;
  }
  const filtersAST = QueryToSqlHelper.filtersMapper(regularFilters as Filter[]);
  return sqlFiltersAST && filtersAST ? QueryToSqlHelper.and(filtersAST, sqlFiltersAST) : sqlFiltersAST ?? filtersAST;
};

export const mergeSegmentations = (
  s1: Segmentation[] | undefined,
  s2: Segmentation[] | undefined,
  metrics: MetricIdType[],
  maxSegmentationLevel: number
): Segmentation[] | undefined => {
  if (metrics.length > 1) {
    return s1 && s1.length > 0 ? s1 : undefined;
  }
  const ss: Segmentation[] = [...(s1 ?? []), ...(s2 ?? [])];
  if (ss.length > 0 && ss.length <= maxSegmentationLevel) {
    return ss;
  } else {
    return s1 && s1.length > 0 ? s1 : undefined;
  }
};

export const shortenLabel = (label: string, maxChars = 10): string => {
  const shortenedSegment = label.substring(0, maxChars);
  return label.length > maxChars ? shortenedSegment : label;
};

// TODO: could use a generic type for label so that we don't have to cast
// but that means using GenderValues, ManagerOrIcValues, etc when parsing the backend response
export const translateLabel = (label: string | null, dataField: DataFieldWithDataType): string | null => {
  return match(dataField)
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
      () => genderTranslationKeyMap[label as GenderValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_OR_IC },
      () => managerOrICTranslationKeyMap[label as ManagerOrIcValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DEFINED_MANAGER },
      () => managerOrICTranslationKeyMap[label as ManagerOrIcValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MARITAL_STATUS },
      () => maritalStatusTranslationKeyMap[label as MartialStatusValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REGIONAL_OR_LOCAL },
      () => regionalLocalTranslationKeyMap[label as RegionalOrLocalValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_OR_NON_LOCAL },
      () => regionalLocalTranslationKeyMap[label as RegionalOrLocalValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REGRET_ATTRITION },
      () => regretNonRegretTranslationKeyMap[label as RegretNonRegretValues]
    )
    .with(
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION },
      () => voluntaryNonVoluntaryTranslationKeyMap[label as VoluntaryInvoluntaryValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.STATUS },
      () => applicationStatusValuesTranslationMap[label as ApplicationStatusValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.STANDARDIZED_CURRENT_STAGE },
      () => applicationCurrentStageValuesTranslationMap[label as ApplicationCurrentStateValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE },
      () => applicationCurrentStageValuesTranslationMap[label as ApplicationCurrentStateValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_1 },
      () => applicationCurrentStageValuesTranslationMap[label as ApplicationCurrentStateValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_2 },
      () => applicationCurrentStageValuesTranslationMap[label as ApplicationCurrentStateValues]
    )
    .with(
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_3 },
      () => applicationCurrentStageValuesTranslationMap[label as ApplicationCurrentStateValues]
    )
    .with(
      { dataType: DataTypes.JOB, dataField: JobDataFields.STATUS },
      () => jobStatusValuesTranslationMap[label as JobStatusValues]
    )
    .with(
      { dataType: DataTypes.OFFER, dataField: OfferDataFields.STATUS },
      () => hireStatusValuesTranslationMap[label as HireStatusValues]
    )
    .otherwise(() => null);
};

export const delay = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export const sortTypeTranslationKeys: Record<SortTypes, string> = {
  [SortTypes.ASC]: 'common:commonValues.sortValues.az',
  [SortTypes.DESC]: 'common:commonValues.sortValues.za',
  [SortTypes.HIGH]: 'common:commonValues.sortValues.highLow',
  [SortTypes.LOW]: 'common:commonValues.sortValues.lowHigh',
} as const;

// @Alex: not sure if it belongs here
export const removeDuplicates = <T>(arr: T[]) =>
  Array.from(new Map(arr.map((item) => [JSON.stringify(item), item])).values()) as T[];
