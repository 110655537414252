import { GraphQlRequestService } from '../../../api/graphql-request-service';
import {
  DomainDependenciesQuery,
  QueryCohortMetricsQuery,
  QueryEmployeeCohortMetricsQuery,
  QueryMetricsQuery,
} from '../../../graphql/generated/graphql-sdk';
import { Domains } from '../../constants';
import { Role } from '../types';
import { handleGQLErrors } from '../utils';
import { cleanupMetricTree, convertMetricTreeToMetricDetails } from '../utils-metrics';
import {
  MetricGroupDetails,
  QueryCohortMetricsParams,
  QueryEmployeeCohortMetricsParams,
  QueryRegularMetricsParams,
} from './types';

export interface MetricsService {
  queryRegularMetrics: (params: QueryRegularMetricsParams) => Promise<QueryMetricsQuery>;
  queryCohortMetrics: (params: QueryCohortMetricsParams) => Promise<QueryCohortMetricsQuery>;
  queryEmployeeCohortMetrics: (params: QueryEmployeeCohortMetricsParams) => Promise<QueryEmployeeCohortMetricsQuery>;
  getMetricTree: () => MetricGroupDetails[];
}

export class BackendMetricsService implements MetricsService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly domainDependencies: DomainDependenciesQuery,
    readonly domain: Domains,
    readonly executorRole: Role,
    readonly simulateRole: Role | null
  ) {}

  public queryRegularMetrics = async (params: QueryRegularMetricsParams): Promise<QueryMetricsQuery> => {
    const { timeSelection, metrics, userFilters, grouping, disableNestLoop } = params;
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.queryMetrics({
        domain: this.domain,
        metrics: metrics.map((m) => m.value),
        disableNestLoop: disableNestLoop ?? null,
        grouping: grouping ?? null,
        timeSelection,
        userFilters: userFilters ?? null,
        selectedExecutorRole: this.executorRole.id,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };

  public queryCohortMetrics = async (params: QueryCohortMetricsParams): Promise<QueryCohortMetricsQuery> => {
    const { timeSelection, metrics, userFilters, disableNestLoop } = params;
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.queryCohortMetrics({
        domain: this.domain,
        metrics: metrics.map((m) => m.value),
        disableNestLoop: disableNestLoop ?? null,
        timeSelection,
        userFilters: userFilters ?? null,
        selectedExecutorRole: this.executorRole.id,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };

  public queryEmployeeCohortMetrics = async (
    params: QueryEmployeeCohortMetricsParams
  ): Promise<QueryEmployeeCohortMetricsQuery> => {
    const { timeSelection, metrics, userFilters, userCohortFilters, grouping, disableNestLoop } = params;
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.queryEmployeeCohortMetrics({
        domain: this.domain,
        metrics: metrics.map((m) => m.value),
        disableNestLoop: disableNestLoop ?? null,
        grouping: grouping ?? null,
        timeSelection,
        userFilters: userFilters ?? null,
        userCohortFilters: userCohortFilters ?? null,
        selectedExecutorRole: this.executorRole.id,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };

  public getMetricTree = (): MetricGroupDetails[] => {
    const metricTree = this.domainDependencies?.domainInfo?.metricTree;
    if (!metricTree) {
      throw new Error('metric tree cannot be null');
    }

    return convertMetricTreeToMetricDetails(cleanupMetricTree(metricTree));
  };
}
