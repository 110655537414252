import { action, runInAction } from 'mobx';
import { AttritionStore } from '../../pages/dashboard/attrition/attrition-store';
import { AliasStore } from '../alias/alias-store';
import { AliasService } from '../api/alias-service';
import { CachedAliasService } from '../api/cached-alias-service';
import { DataUploadService } from '../api/data-upload-service';
import { GraphQlRequestService } from '../api/graphql-request-service';
import { MasterDataApiService, NewBackendMasterDataApiService } from '../api/master-data-service';
import { DefaultPermissionService, PermissionService } from '../api/permission-service';
import { RequestService } from '../api/request-service';
import { UserRolePreferencesService } from '../api/user-role-preferences-service';
import { DefaultUserApiService, UserApiService } from '../api/user-service';
import { AuthService } from '../auth/auth-service';
import { AuthStore } from '../auth/auth-store';
import { ForgotPasswordService } from '../auth/forgot-password-service';
import { BenchmarkStore } from '../benchmark/benchmark-store';
import { ChartDescriptionStore } from '../chart-description/chart-description-store';
import { CompanySettingsStore } from '../company-settings-store';
import { CompanyStore } from '../company/company-store';
import { DashboardStore } from '../dashboard-store';
import { DashboardSettingsStore } from '../dashboard/dashboard-settings-store';
import { EmployeeDataStore } from '../employee-data/employee-data-store';
import { EmployeeProfileStore } from '../employee-profile/employee-profile-store';
import { EnabledFeaturesService } from '../enabled-features-service';
import { environmentService } from '../environment/environment-service';
import { ErrorStore } from '../error-store';
import { ExportStore } from '../export-store';
import { FeatureAccessService } from '../feature-access-service';
import { FilterStore } from '../filter/filter-store';
import { ForecastStore } from '../forecast/forecast-store';
import { IntercomStore } from '../intercom/intercom-store';
import { LoadingStateStore } from '../loading-state-store';
import { LanguageStore } from '../localization/language-store';
import { MetricService } from '../metrics/metric-service';
import { MetricStore } from '../metrics/metric-store';
import { IPermissionsStore, PermissionBasedPermissionsStore } from '../permissions/permissions-store';
import { RecruitmentStore } from '../recruitment/recruitment-store';
import { ReportStore } from '../report/report-store';
import { RouteStore } from '../route-store';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { InitialDependencyStore } from '../startup/initial-dependency-store';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import { UserStore } from '../user/user-store';
import { localStore } from './../local-store';

export interface IRootStore {
  authStore: AuthStore;
  aliasStore: AliasStore;
  attritionStore: AttritionStore;
  benchmarkStore: BenchmarkStore;
  chartDescriptionStore: ChartDescriptionStore;
  companySettingsStore: CompanySettingsStore;
  companyStore: CompanyStore;
  dashboardSettingsStore: DashboardSettingsStore;
  dashboardStore: DashboardStore;
  employeeDataStore: EmployeeDataStore;
  employeeProfileStore: EmployeeProfileStore;
  errorStore: ErrorStore;
  exportStore: ExportStore;
  filterStore: FilterStore;
  intercomStore: IntercomStore;
  languageStore: LanguageStore;
  loadingStateStore: LoadingStateStore;
  reportStore: ReportStore;
  recruitmentStore: RecruitmentStore;
  userStore: UserStore;
  forecastStore: ForecastStore;
  routeStore: RouteStore;
  permissionsStore: IPermissionsStore;
  permissionService: PermissionService;
  graphQlRequestService: GraphQlRequestService;
  userApiService: UserApiService;
  cachedAliasService: CachedAliasService;
  userRolePreferencesService: UserRolePreferencesService;
  enabledFeaturesService: EnabledFeaturesService;
  initialDependencyStore: InitialDependencyStore;
}
class RootStore implements IRootStore {
  public readonly chartDescriptionStore = new ChartDescriptionStore();
  public readonly authService = new AuthService();
  public readonly authStore: AuthStore = new AuthStore(this.authService);
  public readonly graphQlRequestService: GraphQlRequestService = new GraphQlRequestService(
    this.authStore,
    this.authService
  );
  public readonly initialDependencyStore: InitialDependencyStore = new InitialDependencyStore(
    this.graphQlRequestService
  );
  public readonly companyStore = new CompanyStore(this.initialDependencyStore);
  public readonly dashboardSettingsStore = new DashboardSettingsStore();
  public readonly employeeProfileStore = new EmployeeProfileStore();
  public readonly errorStore = new ErrorStore();
  public readonly exportStore = new ExportStore();
  public readonly requestService = new RequestService();
  public readonly routeStore = new RouteStore(this.requestService);
  public readonly languageStore = new LanguageStore();
  public readonly intercomStore = new IntercomStore(this.languageStore);
  public readonly loadingStateStore = new LoadingStateStore();

  public readonly domainDependencyStore: DomainDependencyStore = new DomainDependencyStore(
    this.graphQlRequestService,
    this.companyStore
  );
  public readonly employeeDataStore: EmployeeDataStore = new EmployeeDataStore(this.companyStore);
  public readonly companySettingsStore: CompanySettingsStore = new CompanySettingsStore(this.companyStore);
  public readonly timeSliderStore: TimeSliderStore = new TimeSliderStore(
    this.companySettingsStore,
    this.domainDependencyStore,
    this.employeeDataStore
  );
  public readonly benchmarkStore: BenchmarkStore = new BenchmarkStore(this.timeSliderStore);
  public readonly filterStore: FilterStore = new FilterStore(
    this.benchmarkStore,
    this.timeSliderStore,
    this.domainDependencyStore
  );
  public readonly recruitmentStore: RecruitmentStore = new RecruitmentStore(
    this.filterStore,
    this.domainDependencyStore,
    this.companyStore,
    this.timeSliderStore
  );
  public readonly forecastStore: ForecastStore = new ForecastStore(this.timeSliderStore);
  public readonly userStore: UserStore = new UserStore(
    this.companyStore,
    this.initialDependencyStore,
    this.languageStore
  );
  public readonly cachedAliasService: CachedAliasService = new CachedAliasService(
    new AliasService(this.graphQlRequestService)
  );
  public readonly aliasStore: AliasStore = new AliasStore(this.cachedAliasService, this.languageStore);
  public readonly reportStore = new ReportStore(this.companyStore, this.employeeDataStore, this.companySettingsStore);
  public readonly userApiService: UserApiService = new DefaultUserApiService(this.graphQlRequestService);
  public readonly permissionService: PermissionService = new DefaultPermissionService(this.graphQlRequestService);
  public readonly userRolePreferencesService: UserRolePreferencesService = new UserRolePreferencesService(
    this.graphQlRequestService
  );
  public readonly metricService: MetricService = new MetricService(this.domainDependencyStore);
  public readonly dashboardStore = new DashboardStore();
  public readonly permissionsStore: IPermissionsStore = new PermissionBasedPermissionsStore(
    this.companyStore,
    this.permissionService,
    this.initialDependencyStore,
    this.dashboardStore
  );
  public readonly enabledFeaturesService: EnabledFeaturesService = new EnabledFeaturesService(
    this.permissionsStore,
    this.companyStore,
    environmentService
  );
  public readonly featureAccessService: FeatureAccessService = new FeatureAccessService(
    this.enabledFeaturesService,
    this.permissionsStore
  );

  public readonly masterDataApiService: MasterDataApiService = new NewBackendMasterDataApiService(
    this.graphQlRequestService,
    this.permissionsStore,
    this.requestService,
    this.authService
  );

  public readonly metricStore: MetricStore = new MetricStore(
    this.permissionsStore,
    this.companySettingsStore,
    this.metricService
  );

  public readonly dataUploadService = new DataUploadService(
    this.graphQlRequestService,
    this.companyStore,
    this.requestService,
    this.authService
  );

  public readonly forgotPasswordService: ForgotPasswordService = new ForgotPasswordService(
    this.companyStore,
    this.requestService,
    this.authService
  );

  public readonly attritionStore = new AttritionStore(
    this.companySettingsStore,
    this.timeSliderStore,
    this.reportStore
  );

  constructor() {
    this.reInitSavedStores();
  }

  @action
  public masterReset = () => {
    this.benchmarkStore.resetBenchmark();
    this.timeSliderStore.resetTimeslider();
    this.forecastStore.resetForecast();
    this.dashboardSettingsStore.resetDashboardSettings();
  };

  private async restoreStateFromLocalStorage(storeName: string, rootStore: any) {
    return localStore.getAsync(storeName).then((storeData) => {
      if (storeData) {
        const storeDataAsObject = JSON.parse(storeData);
        Object.entries(storeDataAsObject).forEach(([key, value]: [string, unknown]) => {
          rootStore[storeName][key] = value;
        });
      }
    });
  }

  public reInitSavedStores = (): void => {
    localStore.STORES_TO_REINIT.forEach((storeName: string) => {
      this.restoreStateFromLocalStorage(storeName, this).then(() => {
        runInAction(() => (localStore.storesReInited = true));
      });
    });
  };
}

export const rootStore = new RootStore();
