import { action, computed, observable } from 'mobx';
import { FAVORITE_DASHBOARDS_KEY } from './api/api-interfaces';
import { DataTypes } from './constants/constants';
import { localStore } from './local-store';
import { Dashboards } from './v2/common/components/dashboards/types';

export class DashboardStore {
  @computed
  private get dashboardToLatestVersionDataTypeMap(): Partial<Record<Dashboards, DataTypes>> {
    return {
      [Dashboards.EVALUATION]: DataTypes.EVALUATION,
      [Dashboards.PAYROLL]: DataTypes.PAYROLL,
      [Dashboards.WORK_HOURS_AND_OT]: DataTypes.TIMEANDATTENDANCEMONTHLY,
      [Dashboards.RECRUITMENT_OVERVIEW]: DataTypes.APPLICATION,
      [Dashboards.SURVEY]: DataTypes.QUESTIONANSWER,
    };
  }

  public getLatestVersionDataTypeForDashboard = (dashboard: Dashboards) =>
    this.dashboardToLatestVersionDataTypeMap[dashboard] ?? DataTypes.EMPLOYEE;

  @observable
  public isFullScreen: boolean = false;

  @action
  public setFullScreen = (isFullScreen: boolean) => {
    this.isFullScreen = isFullScreen;
  };

  public toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
  };

  public getFavoriteDashboards() {
    return JSON.parse(localStore.get(FAVORITE_DASHBOARDS_KEY) ?? JSON.stringify({}));
  }

  public addDashboardToFavoriteDashboards(dashboard: Dashboards) {
    const favoriteDashboards = this.getFavoriteDashboards();
    localStore.set(
      FAVORITE_DASHBOARDS_KEY,
      JSON.stringify({
        ...favoriteDashboards,
        [dashboard]: (favoriteDashboards[dashboard] ?? 0) + 1,
      })
    );
  }
}
