import memoize from 'fast-memoize';
import isEqual from 'lodash.isequal';
import { computed } from 'mobx';
import { DataFieldWithDataType } from '../../common-types';
import { AttritionSecondCheckboxField, AttritionTypes, attritionTypes } from '../pages/dashboard/attrition/types';
import { FilterGroup, MedianBaseToggleOptions } from './api/api-interfaces';
import {
  ApiAgeGroup,
  ApiTenureGroup,
  DashboardSettings,
  NonCoreDashboardSettings,
  PerformanceCycle,
  SortSettingsType,
} from './api/zod-schemas';
import { CompanyStore } from './company/company-store';
import {
  DEFAULT_OPTION,
  DataTypes,
  Domains,
  EmployeeDataFields,
  LeaverDateField,
  LeaverDateNormalizedField,
  Operations,
  ROUTE_URLS,
  StandardizedEmpTypes,
} from './constants/constants';
import { Months } from './date-manager/date-manager-constants';
import { MetricCategoryId, MetricGroupId, MetricId } from './graphql/generated/graphql-sdk';
import { FrontendMetricId } from './metrics/backendMetricMappings';
import { ApiMasterDataQueryFilterItem } from './v2/types';

enum FilterGroups {
  FTE = 'FTE',
  REGULAR = 'REGULAR',
  NONREGULAR = 'NONREGULAR',
  CONTRACT = 'CONTRACT',
  ALL = 'ALL',
  SGA = 'SGA',
  COGS = 'COGS',
}

export class CompanySettingsStore {
  private companyStore: CompanyStore;

  public constructor(companyStore: CompanyStore) {
    this.companyStore = companyStore;
  }

  private employmentTypeFilter: (filterValues: string[][]) => ApiMasterDataQueryFilterItem = (
    filterValues: string[][]
  ) => {
    return {
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.EMPLOYMENT_TYPE,
      values: filterValues,
      operation: Operations.EQUAL,
    };
  };

  public standardizedEmpTypeToEmpTypeFilterMap = (): Record<StandardizedEmpTypes, ApiMasterDataQueryFilterItem[]> => {
    return {
      [StandardizedEmpTypes.FTE]: this.filterGroupAsString(FilterGroups.FTE) ?? [
        this.employmentTypeFilter([['Permanent']]),
      ],
      [StandardizedEmpTypes.Regular]: this.filterGroupAsString(FilterGroups.REGULAR) ?? [
        this.employmentTypeFilter([['Permanent']]),
      ],
      [StandardizedEmpTypes.Contract]: this.filterGroupAsString(FilterGroups.CONTRACT) ?? [
        this.employmentTypeFilter([['Contract']]),
      ],
      [StandardizedEmpTypes.NonRegular]: this.filterGroupAsString(FilterGroups.NONREGULAR) ?? [
        this.employmentTypeFilter([['Contract'], ['Permanent']]),
      ],
    };
  };

  public filterGroupAsString(name: FilterGroups): ApiMasterDataQueryFilterItem[] | undefined {
    const fgs = this.companyStore?.domainPreferences?.filterGroups as FilterGroup[];
    return fgs?.find((fg: FilterGroup) => fg.name === name)?.filters;
  }

  private sortSettings = (): SortSettingsType[] | null => {
    const sortSettings = this.companyStore.domainPreferences?.sortSettings;
    return sortSettings ?? null;
  };

  public getSortOrderForField = (field: DataFieldWithDataType): string[] | null => {
    return this.sortSettings()?.find((s) => isEqual(s.field, field))?.sortOrder ?? null;
  };

  public firstMonthOfYear = (): Months => {
    const firstMonthOfYearFromDomainSettings = this.companyStore.domainPreferences?.finYearStartMonth;
    return firstMonthOfYearFromDomainSettings ?? Months.January;
  };

  public paidLeaveFirstMonth = (): Months => {
    const paidLeaveFirstMonthFromDomainSettings = this.companyStore.domainPreferences?.paidLeaveCycleStartMonth;
    return paidLeaveFirstMonthFromDomainSettings ?? this.firstMonthOfYear();
  };

  public evaluationCyclesInfo = (): PerformanceCycle[] => {
    const cycles = this.companyStore.domainPreferences?.evaluationSettings?.evaluationCycles as PerformanceCycle[];
    return cycles ?? [];
  };

  public minTenureFilter = () => {
    const { domain } = this.companyStore;
    const { THREE_MONTH, ONE_YEAR } = attritionTypes;
    switch (domain) {
      case Domains.JT:
      case Domains.KHNEOCHEM:
      case Domains.MSAD:
      case Domains.SHIRATORI:
        return ONE_YEAR;
      default:
        return THREE_MONTH;
    }
  };

  public defaultFilters = (): ApiMasterDataQueryFilterItem[] => {
    const { domain } = this.companyStore;
    return this.domainToDefaultFiltersMap[domain as Domains] || [];
  };

  public customMyPanalytMetrics = (): FrontendMetricId[] | null => {
    const { domain } = this.companyStore;
    return this.domainToCustomMyPanalytMetricsMap[domain as Domains] ?? null;
  };

  public allowedFiltersForDomain = (): DataFieldWithDataType[] => {
    const allowedFilters: DataFieldWithDataType[] = this.companyStore.domainPreferences?.allowedFilters ?? [];
    return allowedFilters;
  };

  public isFilterEnabledForDomain = memoize((filterDimension: DataFieldWithDataType) => {
    return this.allowedFiltersForDomain().deepCompareContains(filterDimension);
  });

  @computed
  public get metricGroupCategorySettings(): { metricGroup: MetricGroupId; category: MetricCategoryId }[] | null {
    const metricGroupCategorySettings = this.companyStore.domainPreferences?.metricGroupCategorySettings;
    if (metricGroupCategorySettings) {
      return metricGroupCategorySettings as { metricGroup: MetricGroupId; category: MetricCategoryId }[];
    } else {
      return null;
    }
  }

  @computed
  public get allowedMetricsForDomain(): MetricId[] {
    const allowedMetrics = this.companyStore.domainPreferences?.allowedMetricIds ?? [];
    return allowedMetrics as MetricId[];
  }

  public isMetricEnabledForDomain = memoize((metric: MetricId) => {
    return !!this.allowedMetricsForDomain.find((m) => m === metric);
  });

  private domainToDefaultFiltersMap: Partial<Record<Domains, ApiMasterDataQueryFilterItem[]>> = {
    [Domains.MIXI_COMPANY_WIDE]: [
      {
        property: EmployeeDataFields.EMPLOYMENT_TYPE,
        operation: Operations.EQUAL,
        values: [['正社員']],
        dataType: DataTypes.EMPLOYEE,
      },
      {
        property: EmployeeDataFields.EMPLOYMENT_TYPE,
        operation: Operations.EQUAL,
        values: [['正社員（出向）']],
        dataType: DataTypes.EMPLOYEE,
      },
    ],
    [Domains.J_OILMILLS]: [
      {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.ORGANIZATION,
        operation: Operations.EQUAL,
        values: [['(株)J-ｵｲﾙﾐﾙｽﾞ']],
      },
      {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.ORGANIZATION,
        operation: Operations.EQUAL,
        values: [['株式会社J-パック']],
      },
      {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.ORGANIZATION,
        operation: Operations.EQUAL,
        values: [['JNP']],
      },
      {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.ORGANIZATION,
        operation: Operations.EQUAL,
        values: [['JWS']],
      },
    ],
  };

  private domainToCustomMyPanalytMetricsMap: Partial<Record<Domains, FrontendMetricId[]>> = {
    [Domains.MSAD]: [
      FrontendMetricId.FEMALE_MANAGERS_DEFINED_PERC_OF_MANAGERS,
      FrontendMetricId.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD_PERC_OF_HC,
      FrontendMetricId.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD_PERC_OF_HC,
      FrontendMetricId.DIGITAL_EXPERTS_MSAD_PERC_OF_HC,
      FrontendMetricId.MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD_PERC_OF_HC,
      FrontendMetricId.HIGHLY_STRESSED_EMPLOYEE_MSAD_PERC_OF_HC,
    ],
    [Domains.BRIDGESTONE]: [
      FrontendMetricId.CUSTOM_INDIVIDUALS_1_BRIDGESTONE_COUNT,
      FrontendMetricId.CUSTOM_JOINERS_1_BRIDGESTONE_JOINERS,
      FrontendMetricId.CUSTOM_JOINERS_2_BRIDGESTONE_JOINERS,
      FrontendMetricId.CUSTOM_ATTRITION_1_BRIDGESTONE_CUSTOM_ATTRITION,
      FrontendMetricId.CUSTOM_LEAVERS_2_BRIDGESTONE_LEAVERS,
      FrontendMetricId.FEMALE_MANAGERS_DEFINED_PERC_OF_MANAGERS,
    ],
  };

  private domainToNamePropertyMap: () => Partial<Record<Domains | DEFAULT_OPTION, DataFieldWithDataType>> = () => ({
    ...Object.fromEntries(
      this.companyStore.japaneseEnterpriseClientDomains.map((d) => [
        d,
        { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FULL_NAME },
      ])
    ),
    [DEFAULT_OPTION]: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME },
  });

  public nameProperty = (): DataFieldWithDataType => {
    const { domain } = this.companyStore;
    return (this.domainToNamePropertyMap()[domain as Domains] ||
      this.domainToNamePropertyMap()[DEFAULT_OPTION]) as DataFieldWithDataType;
  };

  private disabledForecastDomains: Set<Domains> = new Set([Domains.JT]);

  public defaultLandingPage = ROUTE_URLS.MY_PANALYT;

  public attritionSecondCheckboxField = (): AttritionSecondCheckboxField | null => {
    const { domain } = this.companyStore;
    switch (domain) {
      case Domains.JT:
      case Domains.SHIZUOKAGAS:
      case Domains.NECNSI:
        return EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION;
      default:
        return EmployeeDataFields.REGRET_ATTRITION;
    }
  };

  public showAnnualisedSalaryOption = (): boolean => {
    const { domain } = this.companyStore;
    switch (domain) {
      case Domains.SWAN:
        return true;
      default:
        return false;
    }
  };

  public managerProperty = (): DataFieldWithDataType => {
    const { domain } = this.companyStore;
    switch (domain) {
      case Domains.JT:
        return { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DEFINED_MANAGER };
      default:
        return { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_OR_IC };
    }
  };

  public isUsingEffectiveLeaverDate = (): boolean => {
    return this.companyStore.domainPreferences?.isUsingEffectiveLeaverDate === true;
  };

  public medianBaseToggle = (): MedianBaseToggleOptions => {
    return this.companyStore.domainPreferences?.medianBaseToggle ?? MedianBaseToggleOptions.MONTHLY;
  };

  public dashboardSettings = (): DashboardSettings | null => {
    return this.companyStore.domainPreferences?.dashboardSettings ?? null;
  };

  public nonCoreDashboardSettings = (): NonCoreDashboardSettings | null => {
    return this.companyStore.domainPreferences?.nonCoreDashboardSettings ?? null;
  };

  public leaverDateField = (): LeaverDateField => {
    if (this.isUsingEffectiveLeaverDate()) {
      return EmployeeDataFields.EFFECTIVE_LEAVER_DATE;
    }
    return EmployeeDataFields.TERM_DATE;
  };

  public leaverDateNormalizedField = (): LeaverDateNormalizedField => {
    if (this.isUsingEffectiveLeaverDate()) {
      return { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED };
    }
    return { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED };
  };

  public defaultAttritionType = (): AttritionTypes => {
    return this.companyStore.domainPreferences?.defaultAttritionType ?? AttritionTypes.ANNUALISED_ATTRITION;
  };

  public configuredTimeSliderRangeStart = (): string | null => {
    const timeSliderRangeStart = this.companyStore.domainPreferences?.timeSliderRangeStart;
    return timeSliderRangeStart ?? null;
  };

  public referenceCurrency = (): string | null => {
    const referenceCurrency = this.companyStore.domainPreferences?.referenceCurrency;
    return referenceCurrency ?? null;
  };

  public ageGroups = (): ApiAgeGroup[] | null => {
    const ageGroups = this.companyStore.domainPreferences?.ageGroups;
    return ageGroups ?? null;
  };

  public tenureGroups = (): ApiTenureGroup[] | null => {
    const tenureGroups = this.companyStore.domainPreferences?.tenureGroups;
    return tenureGroups ?? null;
  };

  public rootEmpId = (): string | null => {
    const rootEmpId = this.companyStore.domainPreferences?.rootEmpId;
    return rootEmpId ?? null;
  };

  public autoLogoutTimeInMinutes = (): number | null => {
    const autoLogoutTimeInMinutes = this.companyStore.domainPreferences?.autoLogoutTimeInMinutes;
    return autoLogoutTimeInMinutes ?? null;
  };

  public warningToLogoutTimeInMinutes = (): number | null => {
    const warningToLogoutTimeInMinutes = this.companyStore.domainPreferences?.warningToLogoutTimeInMinutes;
    return warningToLogoutTimeInMinutes ?? null;
  };

  public isForecastEnabled = (): boolean => {
    return !this.disabledForecastDomains.has(this.companyStore.domain as Domains);
  };

  @computed
  private get customLandingPage() {
    const customLandingPage = this.companyStore.domainPreferences?.customLandingPage;
    return customLandingPage || null; // Handle empty string
  }

  @computed
  public get companyLandingPage() {
    return this.customLandingPage || this.defaultLandingPage;
  }
}
