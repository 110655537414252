// using dashboard-configurations for unchangeable settings such as allowedGranularity
// or allowedBenchmarks. Updateable settings sit in dashboard-settings-store

import { BenchmarkTypes, InternalBenchmarkTypes, TimeBenchmarkTypes } from '../benchmark/types';
import { RecruitmentDashboards } from '../constants/constants';
import { Granularity } from '../date-manager/date-manager-constants';
import { Dashboards } from '../v2/common/components/dashboards/types';

type DashboardConfiguration<T> = Partial<Record<Dashboards, T>>;

export enum V2Features {
  FILTERS = 'filters',
  SEGMENTS = 'segments',
  TIME_SLIDER = 'timeSlider',
}

const PBS_SHEET_PAGE = 'pbs-overview';
const RBS_SHEET_PAGE = 'rbs-overview';
class DashboardConfigurations {
  // configurations

  private dashboardToV2Features: DashboardConfiguration<V2Features[]> = {
    [Dashboards.RECRUITMENT_OVERVIEW]: [V2Features.FILTERS],
    [Dashboards.EVALUATION]: [V2Features.FILTERS],
    [Dashboards.PAYROLL]: [V2Features.FILTERS],
    [Dashboards.JOINERS_AND_LEAVERS]: [V2Features.FILTERS],
    [Dashboards.MEDIAN_BASE]: [V2Features.FILTERS],
    [Dashboards.GENDER_DIVERSITY]: [V2Features.FILTERS],
    [Dashboards.ATTRITION]: [V2Features.FILTERS],
    [Dashboards.PEOPLE_SNAPSHOT]: [V2Features.FILTERS],
    [Dashboards.PEOPLE_BALANCE_SHEET]: [V2Features.FILTERS, V2Features.SEGMENTS],
    [Dashboards.RECRUITMENT_BALANCE_SHEET]: [V2Features.FILTERS, V2Features.SEGMENTS],
  };

  // benchmark
  private dashboardToAllowedBenchmarksMap: DashboardConfiguration<BenchmarkTypes[]> = {
    [Dashboards.PEOPLE_BALANCE_SHEET]: [
      InternalBenchmarkTypes.MyPop,
      TimeBenchmarkTypes.PRIOR_PERIOD,
      TimeBenchmarkTypes.FINQUARTER,
      TimeBenchmarkTypes.YEAR,
      TimeBenchmarkTypes.SPECIFIC_PERIOD,
    ],
    [Dashboards.RECRUITMENT_BALANCE_SHEET]: [
      // InternalBenchmarkTypes.MyPop,
      // TimeBenchmarkTypes.PRIOR_PERIOD,
      // TimeBenchmarkTypes.FINQUARTER,
      TimeBenchmarkTypes.YEAR,
    ],
    [Dashboards.PEOPLE_SNAPSHOT]: [
      TimeBenchmarkTypes.PRIOR_PERIOD,
      TimeBenchmarkTypes.FINQUARTER,
      TimeBenchmarkTypes.YEAR,
    ],
    [Dashboards.MEDIAN_BASE]: [TimeBenchmarkTypes.PRIOR_PERIOD, TimeBenchmarkTypes.FINQUARTER, TimeBenchmarkTypes.YEAR],
    [Dashboards.ATTRITION]: [TimeBenchmarkTypes.PRIOR_PERIOD, TimeBenchmarkTypes.FINQUARTER, TimeBenchmarkTypes.YEAR],
  };
  private defaultAllowedBenchmarks: BenchmarkTypes[] = [];

  private defaultAllowedGranularity: Granularity[] = [Granularity.MONTH];
  private allGranularities = [Granularity.MONTH, Granularity.FINQUARTER, Granularity.YEAR, Granularity.FINYEAR];

  // granularity
  private dashboardToAllowedGranularityMap: DashboardConfiguration<Granularity[]> = {
    [Dashboards.PEOPLE_SNAPSHOT]: this.allGranularities,
    [Dashboards.ATTRITION]: this.allGranularities,
    [Dashboards.JOINERS_AND_LEAVERS]: this.allGranularities,
    [Dashboards.MEDIAN_BASE]: this.allGranularities,
    [Dashboards.GENDER_DIVERSITY]: this.allGranularities,
    [Dashboards.PEOPLE_BALANCE_SHEET]: this.allGranularities,
    [Dashboards.PAYROLL]: this.allGranularities,
    [Dashboards.RECRUITMENT_BALANCE_SHEET]: this.allGranularities,
  };

  // forecast
  private dashboardsWithForecast: Dashboards[] = [
    Dashboards.PAYROLL,
    Dashboards.JOINERS_AND_LEAVERS,
    Dashboards.PEOPLE_SNAPSHOT,
    Dashboards.ATTRITION,
    Dashboards.MEDIAN_BASE,
  ];

  // segmentation
  private dashboardPagesWithSegmentation: string[] =
    //TODO: Change this to enum
    ['pbs-overview', 'rbs-overview'];

  // beta
  private betaDashboards: Dashboards[] = [...RecruitmentDashboards];

  // pop widget
  private dashboardsWOPopWidget: Dashboards[] = [Dashboards.ATTRITION, Dashboards.RECRUITMENT_OVERVIEW];

  // dataviewoptions
  private dashboardsWithoutDataViewOptions: Dashboards[] = [Dashboards.RECRUITMENT_OVERVIEW];

  // exportDataOptions(export data option from chart view)
  private pagesWithExportDataOptions = [PBS_SHEET_PAGE, RBS_SHEET_PAGE];

  // dataView
  private pagesWithoutDataView = ['survey-sentiment', RBS_SHEET_PAGE, 'recruitment-funnel-overview'];

  // filterTray
  private dashboardWithoutFilterTray: Dashboards[] = [];

  // public methods
  public isBetaDashboard = (db: Dashboards) => {
    return this.betaDashboards.includes(db);
  };

  public isPopWidgetNotAllowed = (db: Dashboards) => {
    return this.dashboardsWOPopWidget.includes(db);
  };

  public isSegmentationAllowedforDbPage = (dbPage: string) => {
    // need to provide for the disable prop
    return this.dashboardPagesWithSegmentation.includes(dbPage);
  };

  public getV2Features = (db: Dashboards): V2Features[] => {
    return this.dashboardToV2Features[db] ?? [];
  };

  public isV2SegmentEnabledForDb = (db: Dashboards): boolean => {
    return this.getV2Features(db).includes(V2Features.SEGMENTS);
  };

  public getAllowedBenchmarks = (db: Dashboards): BenchmarkTypes[] => {
    return this.dashboardToAllowedBenchmarksMap[db] ?? this.defaultAllowedBenchmarks;
  };

  public isBenchmarkAllowedForDb = (benchmark: BenchmarkTypes, db: Dashboards) => {
    return this.getAllowedBenchmarks(db).includes(benchmark);
  };

  public getAllowedGranularity = (db: Dashboards): Granularity[] => {
    return this.dashboardToAllowedGranularityMap[db] ?? this.defaultAllowedGranularity;
  };

  public isGranularityAllowedForDb = (granularity: Granularity, db: Dashboards) => {
    return this.getAllowedGranularity(db).includes(granularity);
  };

  public isForecastAllowedForDb = (dashboard: Dashboards) => {
    return this.dashboardsWithForecast.includes(dashboard);
  };

  public isDataViewOptionsEnabledForDb = (db: Dashboards) => {
    return !this.dashboardsWithoutDataViewOptions.includes(db);
  };

  public isExportDataOptionEnabled = (pageId: string) => {
    return this.pagesWithExportDataOptions.includes(pageId);
  };

  public isDataViewEnabled = (pageId: string) => {
    return !this.pagesWithoutDataView.includes(pageId);
  };

  public isFilterTrayEnabled = (db: Dashboards) => {
    return !this.dashboardWithoutFilterTray.includes(db);
  };
}

export const dashboardConfigurations = new DashboardConfigurations();
