import { cloneDeep } from 'lodash';
import moment from 'moment';
import { match, P } from 'ts-pattern';
import { TimeRange, TimeRangeType } from '../../../api/timeranges/types';
import { TimestampUTC } from '../../../types';
import { addMonth, date, isBefore, monthEnd, now, removeYear, yearEnd, yearStart } from '../../../utils-date';
import {
  AllTimePreset,
  CalendarYearMonthlyTimesliderGranularity,
  CalendarYearYearlyTimesliderGranularity,
  FinancialYearQuarterlyTimesliderGranularity,
  FinancialYearYearlyTimesliderGranularity,
  FYTDPreset,
  Last12MonthsPreset,
  Last3MonthsPreset,
  Last6MonthsPreset,
  LastFinancialYearPreset,
  LastYearPreset,
  YTDPreset,
} from './constants';
import {
  Granularity,
  GranularityInfo,
  Presets,
  TimeSliderGranularity,
  TimeSliderPreset,
  TimeSliderState,
} from './types';

const lastXMonthsStart = (x: number, end: TimestampUTC) => monthEnd(addMonth(end, -x));

export const presetToTimeRange = (
  preset: TimeSliderPreset,
  range: TimeRange,
  granularity: TimeSliderGranularity
): { start: TimeRangeType; end: TimeRangeType } => {
  const pattern: { preset: TimeSliderPreset; range: TimeRange; granularity: TimeSliderGranularity } = {
    preset,
    range,
    granularity,
  };
  return match(pattern)
    .with(
      {
        preset: { id: Presets.LAST3MONTHS },
        range: { min: P.instanceOf(Date), max: P.instanceOf(Date), all: P.array(P.instanceOf(Date)) },
        granularity: { value: Granularity.MONTH },
      },
      (v) => {
        const max = date(v.range.max).valueOf();
        const min = date(v.range.min).valueOf();
        const last3MonthsStart = lastXMonthsStart(2, max);
        const start = isBefore(last3MonthsStart, min) ? min : last3MonthsStart;
        return {
          start: new Date(start),
          end: new Date(max),
        };
      }
    )
    .with(
      {
        preset: { id: Presets.LAST6MONTHS },
        range: { min: P.instanceOf(Date), max: P.instanceOf(Date), all: P.array(P.instanceOf(Date)) },
        granularity: { value: Granularity.MONTH },
      },
      (v) => {
        const max = date(v.range.max).valueOf();
        const min = date(v.range.min).valueOf();
        const last6MonthsStart = lastXMonthsStart(5, max);
        const start = isBefore(last6MonthsStart, min) ? min : last6MonthsStart;
        return {
          start: new Date(start),
          end: new Date(max),
        };
      }
    )
    .with(
      {
        preset: { id: Presets.LAST12MONTHS },
        range: { min: P.instanceOf(Date), max: P.instanceOf(Date), all: P.array(P.instanceOf(Date)) },
        granularity: { value: Granularity.MONTH },
      },
      (v) => {
        const max = date(v.range.max).valueOf();
        const min = date(v.range.min).valueOf();
        const last12MonthsStart = lastXMonthsStart(11, max);
        const start = isBefore(last12MonthsStart, min) ? min : last12MonthsStart;
        return {
          start: new Date(start),
          end: new Date(max),
        };
      }
    )
    .with(
      {
        preset: { id: Presets.YTD },
        range: { min: P.instanceOf(Date), max: P.instanceOf(Date), all: P.array(P.instanceOf(Date)) },
        granularity: { value: Granularity.MONTH },
      },
      (v) => {
        const start = monthEnd(yearStart(date(v.range.max).valueOf()));
        const end = date(v.range.max).valueOf();
        return {
          start: new Date(start),
          end: new Date(end),
        };
      }
    )
    .with(
      {
        preset: { id: Presets.LASTYEAR },
        range: { min: P.instanceOf(Date), max: P.instanceOf(Date), all: P.array(P.instanceOf(Date)) },
        granularity: { value: Granularity.MONTH },
      },
      (v) => {
        const max = date(v.range.max).valueOf();
        const min = date(v.range.min).valueOf();
        const lastYearStart = yearStart(removeYear(max, 1));
        const lastYearEnd = yearEnd(lastYearStart);
        const start = isBefore(lastYearStart, min) ? min : lastYearStart;
        return {
          start: new Date(start),
          end: new Date(lastYearEnd),
        };
      }
    )
    .with(
      {
        preset: { id: Presets.YTD },
        range: { min: P.number, max: P.number, all: P.array(P.number) },
        granularity: { value: Granularity.YEAR },
      },
      (v) => {
        return { start: v.range.max, end: v.range.max };
      }
    )
    .with(
      {
        preset: { id: Presets.LASTYEAR },
        range: { min: P.number, max: P.number, all: P.array(P.number) },
        granularity: { value: Granularity.YEAR },
      },
      (v) => {
        const lastYear = v.range.all[v.range.all.length - 2];
        const value = lastYear > v.range.min ? lastYear : v.range.min;
        return { start: value, end: value };
      }
    )
    .with(
      {
        preset: { id: Presets.FYTD },
        range: { min: P.number, max: P.number, all: P.array(P.number) },
        granularity: { value: Granularity.FINYEAR },
      },
      (v) => {
        return { start: v.range.max, end: v.range.max };
      }
    )
    .with(
      {
        preset: { id: Presets.LASTFINANCIALYEAR },
        range: { min: P.number, max: P.number, all: P.array(P.number) },
        granularity: { value: Granularity.FINYEAR },
      },
      (v) => {
        const lastYear = v.range.all[v.range.all.length - 2];
        const value = lastYear > v.range.min ? lastYear : v.range.min;
        return { start: value, end: value };
      }
    )
    .with(
      {
        preset: { id: Presets.FYTD },
        range: {
          min: { year: P.number, quarterOfYear: P.number },
          max: { year: P.number, quarterOfYear: P.number },
          all: P.array({ year: P.number, quarterOfYear: P.number }),
        },
        granularity: { value: Granularity.FINQUARTER },
      },
      (v) => {
        return {
          start: {
            year: v.range.max.year,
            quarterOfYear: 1,
          },
          end: v.range.max,
        };
      }
    )
    .with(
      {
        preset: { id: Presets.LASTFINANCIALYEAR },
        range: {
          min: { year: P.number, quarterOfYear: P.number },
          max: { year: P.number, quarterOfYear: P.number },
          all: P.array({ year: P.number, quarterOfYear: P.number }),
        },
        granularity: { value: Granularity.FINQUARTER },
      },
      (v) => {
        const lastYear = v.range.max.year - 1;
        const year = lastYear > v.range.min.year ? lastYear : v.range.min.year;
        const lastYearQuarters: number[] =
          v.range.all.filter((i) => i.year === year)?.map((i) => i.quarterOfYear) ?? [];
        const quarterOfYear: number = Math.min(...lastYearQuarters);
        return {
          start: {
            year,
            quarterOfYear,
          },
          end: {
            year,
            quarterOfYear: 4,
          },
        };
      }
    )
    .with({ preset: { id: Presets.ALLTIME } }, (v) => ({
      start: v.range.min,
      end: v.range.max,
    }))
    .otherwise(() => {
      throw new Error(`Unsupported preset: ${preset.label}, ${granularity.label}`);
    });
};

// Sadly the JSON.parse function reviver argument uses any so we have to use any as well
export const jsonParserReviver = (key: string, value: any): any => {
  if (typeof value === 'string') {
    try {
      if (key === 'start' || key === 'end' || key === 'min' || key === 'max' || !Number.isNaN(Number(key))) {
        const maybeDate = moment(cloneDeep(value), 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).toDate();
        return maybeDate ?? value;
      }
    } catch (e) {
      return value;
    }
  }

  return value;
};

export const GranularityDefaultInfo: (
  range: Record<Granularity, TimeRange>,
  current: TimestampUTC
) => Record<Granularity, GranularityInfo> = (range: Record<Granularity, TimeRange>, current = now()) => {
  return {
    [Granularity.MONTH]: {
      defaultPreset: Last12MonthsPreset,
      availablePresets: match(range[Granularity.MONTH])
        .with({ all: P.array(P.instanceOf(Date)) }, (value) => {
          const currentYearMonthCount = new Date(current).getMonth() + 1;
          return [
            value.all.length >= 3 ? [Last3MonthsPreset] : [],
            value.all.length >= 6 ? [Last6MonthsPreset] : [],
            value.all.length >= 12 ? [Last12MonthsPreset] : [],
            value.all.length >= currentYearMonthCount + 12 ? [LastYearPreset] : [],
            value.all.length >= 1 ? [YTDPreset] : [],
            [AllTimePreset],
          ];
        })
        .otherwise(() => [[]])
        .flat(),
      granularity: CalendarYearMonthlyTimesliderGranularity,
    },
    [Granularity.YEAR]: {
      defaultPreset: YTDPreset,
      availablePresets: match(range[Granularity.YEAR])
        .with({ all: P.array(P.number) }, (value) => [
          value.all.length >= 2 ? [LastYearPreset] : [],
          value.all.length >= 1 ? [YTDPreset] : [],
          [AllTimePreset],
        ])
        .otherwise(() => [[]])
        .flat(),
      granularity: CalendarYearYearlyTimesliderGranularity,
    },
    [Granularity.FINQUARTER]: {
      defaultPreset: FYTDPreset,
      availablePresets: match(range[Granularity.FINQUARTER])
        .with({ all: P.array({ year: P.number, quarterOfYear: P.number }) }, (value) => {
          const currentYearQuarterCount = (new Date(current).getMonth() + 1) / 3;
          return [
            value.all.length >= 4 + currentYearQuarterCount ? [LastFinancialYearPreset] : [],
            value.all.length >= 1 ? [FYTDPreset] : [],
            [AllTimePreset],
          ];
        })
        .otherwise(() => [[]])
        .flat(),
      granularity: FinancialYearQuarterlyTimesliderGranularity,
    },
    [Granularity.FINYEAR]: {
      defaultPreset: FYTDPreset,
      availablePresets: match(range[Granularity.FINYEAR])
        .with({ all: P.array(P.number) }, (value) => [
          value.all.length >= 2 ? [LastFinancialYearPreset] : [],
          value.all.length >= 1 ? [FYTDPreset] : [],
          [AllTimePreset],
        ])
        .otherwise(() => [[]])
        .flat(),
      granularity: FinancialYearYearlyTimesliderGranularity,
    },
  };
};

const timeRangeTypeToString = (t: TimeRangeType): string => {
  return match(t)
    .with({ year: P.number, quarterOfYear: P.number }, (value) => `${value.year}${value.quarterOfYear}`)
    .with(P.number, (value) => value.toString())
    .with(P.instanceOf(Date), (value) => value.valueOf().toString())
    .otherwise(() => {
      throw new Error(`Unsupported start type': ${t}`);
    });
};

export const timeSliderStateToCacheKey = (timeSliderState: TimeSliderState): string => {
  const startAsString = timeRangeTypeToString(timeSliderState.start);
  const endAsString = timeRangeTypeToString(timeSliderState.end);

  const keyParts: string[] = [timeSliderState.selectedGranularity.id, startAsString, endAsString];
  return keyParts.join('_');
};
