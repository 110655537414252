import sortby from 'lodash.sortby';
import { IDENTITY } from 'mobx-utils';
import { match } from 'ts-pattern';
import { DataFieldWithDataType } from '../../../common-types';
import i18n from '../../../i18n';
import { evaluationStore } from '../../pages/dashboard/evaluation/evaluation-store';
import { $HierarchicalFilterSeparator$, ApiHierarchyItem } from '../api/api-interfaces';
import { ApiCompany } from '../api/zod-schemas';
import { ByDimensionChartSortValueGetter, ByDimensionChartStrategy } from '../components/charts/ByDimensionChart';
import { UndefinedValue } from '../constants/systemValuesTranslation';
import { Granularity } from '../date-manager/date-manager-constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { DateFormat } from '../date-manager/date-manager-types';
import { rootStore } from '../store/root-store';
import { DataFields, DataTypes, EmployeeDataFields, EvaluationDataFields } from './../constants/constants';
import { isValue, ValueGetter } from './chartDataTasks';
import { apiHierarchyItemOrd } from './comparators';
import { getDimensionFromLabel, isHierarchical, sum } from './utils';
import { isNullValue, isValidNumber } from './validators';

export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc',
  HIGH = 'high',
  LOW = 'low',
}

const sortTypeValues: Record<SortTypes, string> = {
  [SortTypes.ASC]: i18n.t('common:commonValues.sortValues.az'),
  [SortTypes.DESC]: i18n.t('common:commonValues.sortValues.za'),
  [SortTypes.HIGH]: i18n.t('common:commonValues.sortValues.highLow'),
  [SortTypes.LOW]: i18n.t('common:commonValues.sortValues.lowHigh'),
};

export const SortType = Object.freeze(sortTypeValues);

interface SortChartLabelInputs {
  labels: string[];
  baseDim: DataFieldWithDataType;
  chartType?: string;
  dataMap?: any;
  dataKey?: string;
  sortType?: string;
  dateFormat?: DateFormat;
  strategy?: ByDimensionChartStrategy;
  sortValueGetter?: ByDimensionChartSortValueGetter<ByDimensionChartStrategy>;
}

interface SortChartLabelsByValuesInputs {
  labels: string[];
  dataMap: any;
  dataKey: any;
  strategy?: ByDimensionChartStrategy;
  sortValueGetter?: ByDimensionChartSortValueGetter<ByDimensionChartStrategy>;
}

interface GetSortValueForLabelFromDataMapInputs {
  dataMap: any;
  label: string;
  dataKey: any;
  valueGetter?: ValueGetter;
  strategy?: ByDimensionChartStrategy;
  sortValueGetter?: ByDimensionChartSortValueGetter<ByDimensionChartStrategy>;
}
export interface SortByInputs<T> {
  objs: T[];
  valueGetter: (obj: T) => string;
  baseDim: DataFieldWithDataType;
  sortType?: string;
  strategy?: ByDimensionChartStrategy;
}

export const sortAlphabeticalComparator = (asc: boolean) => (a: string, b: string) => {
  if (isNullValue(a)) {
    return 1;
  } else if (isNullValue(b)) {
    return -1;
  } else {
    // Configuration for Natual ordering of strings
    // So [x1,x11,x12,x2,x3] => [x1,x2,x3,x11,x12]
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    return asc ? collator.compare(a, b) : collator.compare(b, a);
  }
};

const sortAlphabetical = (asc: boolean) => {
  return sortAlphabeticalComparator(asc);
};

export const sortAvailableDomains = (a: ApiCompany, b: ApiCompany) => {
  if (a.processCompanyData && !b.processCompanyData) {
    return -1;
  } else if (!a.processCompanyData && b.processCompanyData) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const sortChartLabelsNew = (sortInputs: SortChartLabelInputs) => {
  const {
    labels,
    baseDim,
    dataMap,
    dataKey,
    sortType = SortType.asc,
    dateFormat = Granularity.MONTH,
    strategy,
    sortValueGetter,
  } = sortInputs;

  let sortedLabels: string[] = [];
  const dimension = {
    ...baseDim,
    dataField: getDimensionFromLabel(labels?.first() ?? '', baseDim) as EmployeeDataFields,
  };
  const sorterArray = getSorterArrayForDimension(dimension);
  // Handle custom sorting first
  if (baseDim.dataField === 'DATE' && [SortType.asc, SortType.desc].includes(sortType)) {
    if (sortType === SortType.asc) {
      sortedLabels = sortChartLabelsByDates(labels, dateFormat, true);
    } else if (sortType === SortType.desc) {
      sortedLabels = sortChartLabelsByDates(labels, dateFormat, false);
    }
  } else if (sorterArray.length && [SortType.asc, SortType.desc].includes(sortType)) {
    const labelSplits: {
      [key: string]: string | undefined;
    } =
      labels?.reduce((acc: { [key: string]: string | undefined }, label: string) => {
        const h: string[] = label?.split($HierarchicalFilterSeparator$);
        const l = h?.last() ?? '';
        const f = h?.front();
        acc[l] = f?.length > 0 ? f.join($HierarchicalFilterSeparator$) : undefined;
        return acc;
      }, {}) ?? {};
    sortedLabels = sortChartLabelsLexical(Object.keys(labelSplits), dimension, sortType === SortType.asc).map(
      (label: string) => {
        return labelSplits[label] !== undefined
          ? `${labelSplits[label]}${$HierarchicalFilterSeparator$}${label}`
          : label;
      }
    );
  } else {
    // General Sorting functions start here ..
    switch (sortType) {
      case SortType.high:
        sortedLabels = sortChartLabelsByValues({ labels, dataMap, dataKey, strategy, sortValueGetter });
        break;
      case SortType.low:
        sortedLabels = sortChartLabelsByValues({
          labels,
          dataMap,
          dataKey,
          strategy,
          sortValueGetter,
        }).reverse();
        break;
      case SortType.asc:
        sortedLabels = sortChartLabelsAlphabetically(labels, true);
        break;
      case SortType.desc:
        sortedLabels = sortChartLabelsAlphabetically(labels, false);
        break;
      default:
        // tslint:disable-next-line:no-console
        console.error('Unknown sortType ' + sortType + 'detected.');
    }
  }
  sortedLabels.push(sortedLabels.splice(sortedLabels.indexOf(UndefinedValue), 1)[0]);
  return sortedLabels;
};

const sortChartLabelsAlphabetically = (labels: string[], asc: boolean = true) => {
  return labels.sort(sortAlphabeticalComparator(asc));
};

const sortDateLabels = (labels: string[], format: DateFormat, asc: boolean = true) => {
  const { parseDate } = dateManagerService;
  labels.sort((a: string, b: string) =>
    asc
      ? parseDate(a, format).diff(parseDate(b, format), Granularity.DAY)
      : parseDate(b, format).diff(parseDate(a, format), Granularity.DAY)
  );
  return labels;
};

export const sortChartLabelsByDates = sortDateLabels;

const getSortValueForLabelFromDataMap = ({
  label,
  dataMap,
  dataKey,
  strategy = 'basic',
  sortValueGetter,
}: GetSortValueForLabelFromDataMapInputs): number => {
  let value: number;
  const dataObjForLabel = dataMap[label] ?? {};
  if (sortValueGetter) {
    value = sortValueGetter(dataObjForLabel);
  } else if (strategy === 'stacked' || strategy === 'grouped') {
    const sumStack = sum(Object.values(dataObjForLabel).map((v: any) => (isValue(v) ? v : v[dataKey])));
    value = sumStack;
  } else {
    value = isValue(dataObjForLabel) ? dataObjForLabel : dataObjForLabel[dataKey] ?? 0;
  }
  return value;
};

const sortChartLabelsByValues = ({
  labels,
  dataMap,
  dataKey,
  strategy,
  sortValueGetter,
}: SortChartLabelsByValuesInputs) => {
  const newLabels = labels.sort((a: string, b: string) => {
    if (dataMap[a] && dataMap[b]) {
      const valueA = getSortValueForLabelFromDataMap({
        dataMap,
        label: a,
        dataKey,
        strategy,
        sortValueGetter,
      });
      const valueB = getSortValueForLabelFromDataMap({
        dataMap,
        label: b,
        dataKey,
        strategy,
        sortValueGetter,
      });
      return valueB - valueA;
    }
    return 1;
  });
  return newLabels;
};

const getSorterArrayForDimension: (dim: DataFieldWithDataType) => DataFieldWithDataType[] = (
  dim: DataFieldWithDataType
) => {
  const tenureGroupsArray = rootStore.companySettingsStore.tenureGroups()?.map((t) => t.description) ?? [];
  const ageGroupsArray = rootStore.companySettingsStore.ageGroups()?.map((a) => a.description) ?? [];
  const result = () => {
    const modifyDim = (dim: DataFieldWithDataType) => {
      let modifiedDim = { ...dim };
      if (modifiedDim.dataType === DataTypes.JOINERS_VIEW) {
        // We use Employee Datatype sort orders for joiners view as well
        modifiedDim.dataType = DataTypes.EMPLOYEE;
      }
      if (isHierarchical(modifiedDim)) {
        // for base hierarchical field, we use level 1 sort order
        // This may need to change in the future, but keeping it this way as its tightly coupled
        // with how filter tray operates
        modifiedDim.dataField = `${modifiedDim.dataField}_LEVEL_1` as DataFields;
      }
      return modifiedDim;
    };
    const modifiedDim = modifyDim(dim);
    const domainPreferencesSortOrder = rootStore.companySettingsStore.getSortOrderForField(modifiedDim);

    if (domainPreferencesSortOrder) {
      return domainPreferencesSortOrder;
    } else {
      return match(modifiedDim)
        .with({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP }, () => tenureGroupsArray)
        .with(
          { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_TENURE_GROUP },
          () => tenureGroupsArray
        )
        .with({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP }, () => ageGroupsArray)
        .with(
          { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_SCORE },
          () => evaluationStore.sortedPerformanceRatings
        )
        .otherwise(() => {
          return [];
        });
    }
  };
  return result().map((f) => ({ dataType: dim.dataType, dataField: f as DataFields }));
};

export const sortBySorterArray = <T, V>(
  values: T[],
  sorterArray: readonly V[],
  getValueToSortBy: (val: T) => V = IDENTITY,
  asc = true
) => {
  const newValues = values.sort((a, b) => {
    const aValue = getValueToSortBy(a);
    const bValue = getValueToSortBy(b);
    if (sorterArray.indexOf(aValue) === -1) {
      return asc ? 1 : -1;
    } else if (sorterArray.indexOf(bValue) === -1) {
      return asc ? -1 : 1;
    } else {
      return asc
        ? sorterArray.indexOf(aValue) - sorterArray.indexOf(bValue)
        : sorterArray.indexOf(bValue) - sorterArray.indexOf(aValue);
    }
  });
  return newValues;
};

export const sortFilterTrayValues = (
  filterValues: ApiHierarchyItem[],
  dim: DataFieldWithDataType
): ApiHierarchyItem[] => {
  const sorterArray = getSorterArrayForDimension(dim);
  if (sorterArray.length) {
    const sortedFilterValues = sortBySorterArray(
      filterValues,
      sorterArray.map((f) => f.dataField),
      (val) => val.name
    );
    return sortedFilterValues;
  }
  return sortby(filterValues, [
    (o: ApiHierarchyItem) => (isValidNumber(o.name) ? Number(o.name) : isNullValue(o.name) ? null : o.name),
  ]);
};

const sortChartLabelsLexical = (labels: string[], dim: DataFieldWithDataType, asc: boolean = true) => {
  const sorterArray = getSorterArrayForDimension(dim);
  let newLabels = [...labels];
  newLabels = sorterArray.length
    ? sortBySorterArray(
        newLabels,
        sorterArray.map((f) => f.dataField),
        IDENTITY,
        asc
      )
    : newLabels.sort(sortAlphabetical(asc));
  return newLabels;
};

export const sortApiHierarchyItemByValues = (
  apiHierarchyItems: ApiHierarchyItem[],
  baseDim: DataFieldWithDataType,
  asc: boolean = true,
  level: number = 0
): ApiHierarchyItem[] => {
  const dim = {
    dataType: baseDim.dataType,
    dataField: level === 0 ? baseDim.dataField : (`${baseDim.dataField}_LEVEL_${level + 1}` as DataFields),
  };
  const sorterArray = getSorterArrayForDimension(dim);
  let sortedApiHierarchyItems = [...apiHierarchyItems];
  sortedApiHierarchyItems = sorterArray.length
    ? sortBySorterArray(
        sortedApiHierarchyItems,
        sorterArray.map((f) => f.dataField),
        (item) => item.name,
        asc
      )
    : sortedApiHierarchyItems.sort(apiHierarchyItemOrd.compare);
  sortedApiHierarchyItems.forEach((item) => {
    if (item.subItems && item.subItems.length > 1) {
      item.subItems = sortApiHierarchyItemByValues(item.subItems, baseDim, asc, level + 1);
    }
  });
  return sortedApiHierarchyItems;
};

export const sortObjectsBy = <T>(sortByInputs: SortByInputs<T>) => {
  const { objs, valueGetter, baseDim, sortType = SortType.asc } = sortByInputs;
  const valueToObjectMap: Record<string, T[]> = {};
  objs.forEach((obj) => {
    const val = valueGetter(obj);
    if (!valueToObjectMap[val]) {
      valueToObjectMap[val] = [];
    }
    valueToObjectMap[val].push(obj);
  });
  const sortInputs: SortChartLabelInputs = {
    labels: Object.keys(valueToObjectMap),
    baseDim,
    sortType,
  };
  const sortedValues = sortChartLabelsNew(sortInputs);
  const sortedObjs = sortedValues.flatMap((v) => valueToObjectMap[v]);
  return sortedObjs;
};
