import { trackError } from '../sentry/sentry-no-rootstore';
import { DeployTargets, Environment, environmentConstants, Environments } from './environments';
import { localEnvName } from './local-env';

export class EnvironmentService {
  public environment: Environment;
  public isProd: boolean;
  public isDemo: boolean;
  public isLocalhost: boolean;
  public isTest: boolean;
  public isCrexta: boolean;
  public isJt: boolean;
  public isJt2: boolean;
  public isValentinSaml: boolean;
  public isCrextaThroughLink: boolean;

  constructor() {
    const host = window.location.host;
    this.isLocalhost = host.includes('127.0.0.1') || host.includes('localhost');
    this.environment = this.getEnvironment();
    this.isTest = process.env.NODE_ENV === 'test' ? true : false;
    const envName = this.environment.name;

    this.isProd =
      !this.isLocalhost && (envName === Environments.PANACLOUD_JAPAN || envName === Environments.PANACLOUD_DEMO);

    this.isDemo = !this.isLocalhost && envName === Environments.PANACLOUD_DEMO;
    this.isCrexta =
      this.getSubDomain() === 'crexta' ||
      this.getSubDomain() === 'panacloud-crexta' ||
      this.getSubDomain() === 'panalyt-crexta';
    this.isJt =
      this.getSubDomain() === 'jt' || this.getSubDomain() === 'panacloud-jt' || this.getSubDomain() === 'panalyt-jt';
    this.isJt2 =
      this.getSubDomain() === 'jt2' || this.getSubDomain() === 'panacloud-jt2' || this.getSubDomain() === 'panalyt-jt2';
    this.isValentinSaml =
      this.getSubDomain() === 'valentinsaml' ||
      this.getSubDomain() === 'panacloud-valentinsaml' ||
      this.getSubDomain() === 'panalyt-valentinsaml';
    const originParam = new URL(window.location.href).searchParams.get('origin');
    this.isCrextaThroughLink = this.isCrexta && originParam === 'crexta';
  }

  public getSubDomain = () => {
    return window.location.host.split('.')[0];
  };

  public formatUrlNewBackend(initialUrl: string) {
    return new URL(initialUrl, this.getNewBackendApiUrl());
  }

  // Public routes that don't require an authentication header / to be logged in
  public formatUrlNewBackendPublic(initialUrl: string) {
    return new URL(initialUrl, this.getNewBackendApiUrl() + '/public/');
  }

  public getDeployTarget = (): DeployTargets => {
    return process.env.target as DeployTargets;
  };

  public getEnvironment() {
    const host = window.location.host;
    const localEnv = environmentConstants.find((env) => env.name === localEnvName) || environmentConstants[1];
    const deployTarget: DeployTargets = this.getDeployTarget();
    const currentEnv = this.isLocalhost
      ? localEnv
      : environmentConstants
          .filter((e) => e.deployTarget === deployTarget)
          .find((env) => env.hosts.some((envHost) => host === envHost));
    if (currentEnv) {
      return currentEnv;
    } else {
      throw new Error(`Unknown environment! Host: ${host}`);
    }
  }

  public getApiBase = () => {
    return this.environment.apiBase;
  };

  public getNewBackendApiUrl() {
    const apiBase = this.getApiBase();
    return 'https://' + apiBase;
  }

  public getGraphqlUrl() {
    const apiBase = this.getApiBase();
    return 'https://' + apiBase + '/graphql';
  }

  private getFirebaseConfigByEnvironmentAndTarget(deployTarget: string | undefined, environment: Environment) {
    if (deployTarget) {
      if (deployTarget === DeployTargets.PANALYT) {
        return environment.legacyPanalytFirebaseConfig;
      } else if (deployTarget === DeployTargets.PANACLOUD) {
        if (this.isValentinSaml) {
          return this.environment.legacyPanalytFirebaseConfig;
        } else if (this.isJt2 || this.isJt) {
          return this.environment.newPanacloudFirebaseConfig;
        } else {
          return this.environment.newPanacloudFirebaseConfig;
        }
      } else {
        console.warn(`Unexpected deployTarget ${deployTarget}, falling back to legacy panalyt firebase config`);
        return this.environment.legacyPanalytFirebaseConfig;
      }
    } else {
      console.warn('deployTarget not detected, falling back to legacy panalyt firebase config');
      return this.environment.legacyPanalytFirebaseConfig;
    }
  }

  public getFirebaseConfig() {
    const deployTarget = this.getDeployTarget();
    const config = this.getFirebaseConfigByEnvironmentAndTarget(deployTarget, this.environment);
    if (config) {
      return config;
    } else {
      const errorMsg = `No firebase config found for deployTarget ${deployTarget} and ${this.environment.name}. This is a misconfiguration.`;
      console.error(errorMsg);
      trackError(errorMsg, {
        deployTarget: `${deployTarget}`,
        environment: `${this.environment}`,
      });
      throw new Error(errorMsg);
    }
  }

  public getHostUrl() {
    const host = window.location.host;
    return 'https://' + host;
  }

  public getCSRFProtectionToken() {
    switch (this.getEnvironment().name) {
      case Environments.PANACLOUD_JAPAN:
        return 'japan';
      case Environments.PANACLOUD_DEMO:
        return 'demo';
      case Environments.PANACLOUD_QA:
        return 'qa';
      default:
        return this.getEnvironment().name;
    }
  }
}

export const environmentService = new EnvironmentService();
