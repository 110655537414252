import { DashboardConfig } from '../../common/components/dashboards/types';
import { AlexDashboardChartConfig } from './alex_dashboard_config';
import { JTBDashboardChartConfig } from './jtb_dashboard_config';
import { ShaqDashboardChartConfig } from './shaq_dashboard_config';
import { ValentinDashboardChartConfig } from './valentin_dashboard_chart_config';

const defaultDashboardConfigs: DashboardConfig[] = [];
const jtDashboardConfigs: DashboardConfig[] = [];
const swanDashboardConfigs: DashboardConfig[] = [AlexDashboardChartConfig, ShaqDashboardChartConfig];
const allDashboardConfigs: DashboardConfig[] = [
  AlexDashboardChartConfig,
  ShaqDashboardChartConfig,
  ValentinDashboardChartConfig,
  JTBDashboardChartConfig,
];
const jtbDashboardConfigs: DashboardConfig[] = [JTBDashboardChartConfig];

export const defaultDashboardConfigsJson = JSON.stringify(defaultDashboardConfigs);
export const jtDashboardConfigsJson = JSON.stringify(jtDashboardConfigs);
export const jtbDashboardConfigsJson = JSON.stringify(jtbDashboardConfigs);
export const swanDashboardConfigsJson = JSON.stringify(swanDashboardConfigs);
export const allDashboardConfigsJson = JSON.stringify(allDashboardConfigs);
