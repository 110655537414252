import { DashboardPage } from '../../../components/dashboard/dashboard-master';
import { ReadData, ViewDashboards } from '../../../graphql/generated/graphql-sdk';
import { ApiDashboard, DashboardConfig, Dashboards, PageConfig } from '../../common/components/dashboards/types';
import { Domains } from '../../constants';
import { DataFields, DataFieldWithDataType } from '../../types';
import { AssignedPermissions, Permission, PermissionTypes } from '../permission/types';
import { getFilterFieldFromTag } from '../permission/utils';
import { DomainInfo } from '../types';
import { isHierarchical } from '../utils';

export interface AuthorizationService {
  isFieldVisible: (field: DataFieldWithDataType) => boolean;
  isFilterAllowed: (field: DataFieldWithDataType) => boolean;
  isFieldAllowedForExecutorRole: (field: DataFieldWithDataType) => boolean;

  // dashboard
  isDashboardAllowedInExecutorRole: (dbId: string) => boolean;
  isDashboardPageAllowedInExecutorRole: (pageId: string) => boolean;
  canSeeDataView: (dashboard?: Dashboards, currentPage?: DashboardPage) => boolean;
  canExportDataView: (dbId: Dashboards) => boolean;
  canExportChartView: (dbId: Dashboards) => boolean;

  // custom dashboard
  isCustomDashboardAllowedInExecutorRole: (customDb: DashboardConfig) => boolean;
  isCustomDashboardPageAllowedInExecutorRole: (customDbPage: PageConfig) => boolean;

  // panalytSuperAdmin features (can move this to an enabledFeaturesService if need be later)
  canSeeChartDevOptions: () => boolean;
}

export class BackendAuthorizationService implements AuthorizationService {
  constructor(
    readonly domain: Domains,
    readonly allPermissionsForCurrentUser: AssignedPermissions<Permission>,
    readonly executorRolePermissions: AssignedPermissions<Permission>,
    readonly domainInfo: DomainInfo,
    readonly allDashboards: ApiDashboard[]
  ) {}

  private userCanManageAllDomains = () => {
    // PanalytSuperAdmin
    return !!this.allPermissionsForCurrentUser
      .filterByType(PermissionTypes.ManageDomain)
      .map((ap) => ap.permission.domain === null).length;
  };

  private userCanManageThisCompany = () => {
    // SuperAdmin
    return this.executorRolePermissions.filterByType(PermissionTypes.ManageCompany).validFor(this.domain).nonEmpty();
  };

  public isFieldVisible = (field: DataFieldWithDataType): boolean => {
    if (isHierarchical(field)) {
      return this.isFieldAllowedForExecutorRole({
        dataType: field.dataType,
        dataField: `${field.dataField}_LEVEL_1` as DataFields,
      });
    } else {
      return this.isFieldAllowedForExecutorRole(field);
    }
  };

  public isFilterAllowed = (field: DataFieldWithDataType): boolean => {
    const { dataType, dataField } = field;
    const useFilterPermission = this.executorRolePermissions
      .validFor(this.domain)
      .filterByType(PermissionTypes.UseFilters)
      .first()?.permission;

    if (!useFilterPermission) {
      return false;
    } else {
      return (
        useFilterPermission.allowedTags
          ?.map((t) => getFilterFieldFromTag(t))
          .deepCompareContains({ dataType, dataField }) ?? true
      );
    }
  };

  public isFieldAllowedForExecutorRole = (field: DataFieldWithDataType): boolean => {
    return this.isFieldAllowedInPermission(
      field,
      this.executorRolePermissions.filterByType(PermissionTypes.ReadData).first()?.permission
    );
  };

  private isFieldAllowedInPermission = (field: DataFieldWithDataType, permission?: ReadData) => {
    const hasFullAccess = permission?.access === null ?? false;
    const hasSpecificFieldAccess =
      permission?.access?.some(
        (a) =>
          a.datatype === field.dataType &&
          (a.fields?.specificDatafields?.some((f) => f.datafield === field.dataField) ?? false)
      ) ?? false;
    return hasFullAccess || hasSpecificFieldAccess;
  };

  public isDashboardAllowedInExecutorRole = (dbId: string) => {
    return this.isDashboardAllowedInPermission(
      dbId,
      this.executorRolePermissions.filterByType(PermissionTypes.ViewDashboards).first()?.permission
    );
  };

  private isDashboardAllowedInPermission = (dbId: string, permission?: ViewDashboards) => {
    if (permission && permission.dashboardPages === null) {
      return true;
    } else {
      const allowedDashboards = permission?.dashboardPages?.map((dbp) => dbp.dashboard) ?? [];
      return allowedDashboards.includes(dbId);
    }
  };

  public isDashboardPageAllowedInExecutorRole = (pageId: string) => {
    const dashboard = this.getDashboardFromDashboardPage(pageId);
    const isDashboardAllowedForDomain = dashboard.enabled && this.isDashboardAllowedForDomain(dashboard);
    const isDashboardAllowedInPermissions = this.isDashboardPageAllowedInPermission(
      pageId,
      this.executorRolePermissions.filterByType(PermissionTypes.ViewDashboards).first()?.permission
    );
    return isDashboardAllowedForDomain && isDashboardAllowedInPermissions;
  };

  private getDashboardFromDashboardPage = (pageId: string): ApiDashboard => {
    const dashboard = this.allDashboards.find((db) => db.pages.some((p) => p.pageId === pageId)) as ApiDashboard;
    return dashboard;
  };

  private isDashboardAllowedForDomain = (dashboard: ApiDashboard) => {
    const domainAllowedDashboards: string[] =
      (this.domainInfo.latestSettings?.settings.allowedDashboards).map((d: any) => d.id) ?? [];
    return domainAllowedDashboards.includes(dashboard.id);
  };

  private isDashboardPageAllowedInPermission = (pageId: string, permission?: ViewDashboards) => {
    const dashboard = this.getDashboardFromDashboardPage(pageId);
    const dashboardPages = permission?.dashboardPages;
    if (dashboardPages === null) {
      return true;
    } else {
      return Boolean(
        dashboardPages?.find(
          (dbp) => dbp.dashboard === dashboard.id && (dbp.pages === null || dbp.pages.includes(pageId))
        )
      );
    }
  };

  public canSeeDataView = (dashboard?: Dashboards, currentPage?: DashboardPage) => {
    return this.executorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .some(
        (ap) => ap.permission.viewDataView === null || (dashboard && ap.permission.viewDataView.includes(dashboard))
      );
  };

  public canExportDataView = (dbId: Dashboards) => {
    return this.executorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .some((ap) => ap.permission.exportDataView === null || ap.permission.exportDataView.includes(dbId));
  };

  public canExportChartView = (dbId: Dashboards) => {
    return this.executorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .some((ap) => ap.permission.exportChartView === null || ap.permission.exportChartView.includes(dbId));
  };

  public isCustomDashboardAllowedInExecutorRole = (customDb: DashboardConfig) => {
    return this.userCanManageThisCompany();
  };

  public isCustomDashboardPageAllowedInExecutorRole = (customDbPage: PageConfig) => {
    return true;
  };

  public canSeeChartDevOptions = () => {
    return this.userCanManageAllDomains();
  };
}
