import range from 'lodash.range';
import {
  ApplicationDataFields,
  DataFieldWithDataType,
  DataTypes,
  DataTypesAttributes,
  EmployeeDataFields,
  EvaluationDataFields,
  JobDataFields,
  Versioning,
} from './types';

export const MINIMUM_SEARCH_INPUT = 2;

export const ASCII_ARROW = '\u21D2';

export const DELTA_PERCENTAGE = 'deltaPercentage';
export const DEFAULT_CURRENCY = 'JPY';
export const CONFIDENTIAL_VALUE = 'CONFIDENTIAL';

export const UNDEFINED_DISPLAY_KEY = 'common:commonValues.missingValue';
export const CONFIDENTIAL_DISPLAY_KEY = 'common:commonValues.confidential';
export const DATA_NOT_AVAILABLE_VALUE = '-';

export const DEFAULT_OPTION = 'default';
export const $HierarchicalFilterSeparator$ = '$HierarchicalFilterSeparator$';
export const $UndefinedValueIndicator$ = '$UndefinedValueIndicator$';

export const DOMAIN_KEY = 'DOMAIN';
export const SIMULATED_ROLE_KEY = 'SIMULATED_ROLE';
export const EXECUTOR_ROLE_KEY = 'EXECUTOR_ROLE';
export const EXPORT_REF_ID = 'dashboardRoot';

export enum Domains {
  AU_FINANCIAL_HOLDINGS = 'au_financial_holdings',
  BRIDGESTONE = 'bridgestone',
  CISNE = 'cisne',
  IVS2023 = 'ivs2023',
  JT = 'jt',
  JTB = 'jtb',
  J_OILMILLS = 'j_oilmills',
  KHNEOCHEM = 'khneochem',
  LOOOP = 'looop',
  MIXI_COMPANY_WIDE = 'mixi_company_wide',
  MSAD = 'irric',
  NECNSI = 'necnsi',
  NIKKI = 'nikki',
  NSSOL = 'nssol',
  PATAGONIA = 'patagonia',
  PERSOLAPAC = 'persolapac',
  SHIRATORI = 'shiratori',
  SHIZUOKAGAS = 'shizuokagas',
  SWAN = 'swan',
  SWAN_ASIA = 'swan.asia',
}

export enum Languages {
  JA = 'ja',
  EN = 'en',
}

export enum ReservedRoleNames {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  GLOBAL_ACCESS = 'Global Access',
}

export const HierarchicalFields: Set<DataFieldWithDataType> = new Set([
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.POSITION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.NATIONALITY_HIERARCHICAL },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.LOCATION },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.ORGANIZATION },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.EMPLOYMENT_TYPE },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.JOB_GRADE },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.FUNCTION },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.POSITION },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
  { dataType: DataTypes.JOINERS_VIEW, dataField: EmployeeDataFields.NATIONALITY_HIERARCHICAL },
  ...range(1, 10).map((num) => {
    return { dataType: DataTypes.EMPLOYEE, dataField: `CUSTOM_FIELD_${num}` as EmployeeDataFields };
  }),
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.INTERNAL_JOB_GRADE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.INTERNAL_JOB_TITLE },
  { dataType: DataTypes.JOB, dataField: JobDataFields.ORGANIZATION },
  { dataType: DataTypes.JOB, dataField: JobDataFields.LOCATION },
  { dataType: DataTypes.JOB, dataField: JobDataFields.JOB_NAME },
  { dataType: DataTypes.JOB, dataField: JobDataFields.OFFICE },
  { dataType: DataTypes.JOB, dataField: JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
  { dataType: DataTypes.JOB, dataField: JobDataFields.CUSTOM_FIELD_1 },
  { dataType: DataTypes.JOB, dataField: JobDataFields.CUSTOM_FIELD_2 },
  { dataType: DataTypes.JOB, dataField: JobDataFields.JOB_GRADE },
  { dataType: DataTypes.JOB, dataField: JobDataFields.FUNCTION },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.SOURCE },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.REJECTION_REASON },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.CUSTOM_FIELD_1 },
]);

export const DataTypesWithAttributes: Record<DataTypes, DataTypesAttributes> = {
  [DataTypes.MANAGERENPS]: { versioning: Versioning.MONTHLY, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID },
  [DataTypes.EMPLOYEE]: { versioning: Versioning.MONTHLY, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID },
  [DataTypes.APPLICATION]: { versioning: Versioning.MONTHLY, defaultIdColumn: ApplicationDataFields.APPLICATION_ID },
  [DataTypes.RECRUITERJOBS]: { versioning: Versioning.MONTHLY, defaultIdColumn: ApplicationDataFields.APPLICATION_ID }, // TODO: fix this
  [DataTypes.JOB]: { versioning: Versioning.MONTHLY, defaultIdColumn: ApplicationDataFields.APPLICATION_ID }, // TODO: fix this
  [DataTypes.OFFER]: { versioning: Versioning.MONTHLY, defaultIdColumn: ApplicationDataFields.APPLICATION_ID }, // TODO: fix this
  [DataTypes.QUESTIONANSWER]: { versioning: Versioning.NONE, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID }, // TODO: fix this
  [DataTypes.TIMEANDATTENDANCEMONTHLY]: {
    versioning: Versioning.MONTHLY,
    defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID,
  }, // TODO: fix this
  [DataTypes.PAYROLL]: { versioning: Versioning.MONTHLY, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID }, // TODO: fix this
  [DataTypes.EVALUATION]: { versioning: Versioning.NONE, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID }, // TODO: fix this
  [DataTypes.JOINERS_VIEW]: { versioning: Versioning.MONTHLY, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID }, // TODO: fix this
  [DataTypes.FINANCIALMETRICS]: {
    versioning: Versioning.MONTHLY,
    defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID,
  }, // TODO: fix this
  [DataTypes.INTERVIEW]: { versioning: Versioning.MONTHLY, defaultIdColumn: EmployeeDataFields.EMPLOYEE_ID }, // TODO: fix this
};

export const employeeIdDataField = { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID };
export const startDateField = { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE };
export const employmentTemporalityField = {
  dataType: DataTypes.EMPLOYEE,
  dataField: EmployeeDataFields.EMPLOYMENT_TEMPORALITY,
};
export const termDateNormalizedField = {
  dataType: DataTypes.EMPLOYEE,
  dataField: EmployeeDataFields.TERM_DATE_NORMALIZED,
};
export const evaluationCycleTypeField = {
  dataType: DataTypes.EVALUATION,
  dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE,
};
